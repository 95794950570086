import { useEffect } from 'react';

var test = '<div><h1 color="#202124">Κάντε εγγραφή στο Newsletter μας.</h1><form class="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="5B5E7037DA78A748374AD499497E309E86AD4BCD068477E8DEAF268C02E1162DF34800A3A4CFC8821B0D4A676A38367544ED1EBC1690EE172E1B291697E3291B"><div><div><label>Όνομα </label><input aria-label="Όνομα" id="fieldName" maxlength="200" name="cm-name" placeholder="Όνομα"></div><div><label>Email </label><input autocomplete="Email" aria-label="Email" class="js-cm-email-input qa-input-email" id="fieldEmail" maxlength="200" name="cm-nlltlyd-nlltlyd" placeholder="Email" required="" type="email"></div><div><div><div><div><input aria-required="" id="cm-privacy-consent" name="cm-privacy-consent" required="" type="checkbox"><label for="cm-privacy-consent">Συμφωνώ με τους όρους και την πολιτική απορρήτου.</label></div><input id="cm-privacy-consent-hidden" name="cm-privacy-consent-hidden" type="hidden" value="true"></div></div><div><p><a href="https://www.morningview.gr/app-privacy-policy" rel="noopener" target="_blank">Πολιτική απορρήτου</a></p><p><a href="https://www.morningview.gr/eula" rel="noopener" target="_blank">Cookies</a></p></div></div></div><button type="submit">Εγγραφή στο Newsletter</button></form></div>';

const Subscribe = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js';
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

  return (
    <div className="content" dangerouslySetInnerHTML={{__html: test}}></div>
  );
};

export default Subscribe;