import styles from '../../styles/main.module.css'
import LineWithDot from './LineWithDot'

function SideNews(props: any) {
    const {article} = props;
    return (
        <div className='d-flex flex-column justify-content-between'>
            <LineWithDot
                customstyle={{ width: "70px" }}
            />
            <div className={styles.homePageSideNewsTitle}>
                {article.title}
            </div>
            <div className={styles.homePageSideNewsDetails} dangerouslySetInnerHTML={{__html: article.preview ? article.preview + '...' : ''}}></div>
        </div>
    )
}

export default SideNews