import clock from '../../../images/datedark.png';
import newsstyles from '../../../styles/News.module.css'
import { Link } from 'react-router-dom';

const NewsAnalysisSingle = (props:any) => {
    return (
        <Link to={'/article'} state={{id: props.articleId}}>
            <div className={`${newsstyles.newContainerInner} ${'slideInAnimationBottom'}`}>
                    <div className={newsstyles.newsDivImg}>
                        <div className={newsstyles.newsMainImg} style={{backgroundImage: "url('"+props.imagePath+"')"}}></div>
                    </div>
                    <div className={newsstyles.newsDetailsDiv}>
                        <div className={newsstyles.newsTitle}>
                            {props.title}
                        </div>
                        <div className={newsstyles.newsDate}>
                            <img src={clock}></img>
                            <div>{props.date}</div>
                        </div>
                    </div>
            </div>
        </Link>
    );
}
export default NewsAnalysisSingle;