import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import SidebarMenu from './SidebarMenu';
import Advert from './Advert';
import React, { useState } from 'react';
import styles from "./../../styles/main.module.css"
import { Link } from 'react-router-dom';

function SideBar(props: any) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container className={`d-flex flex-column gap-lg-4 gap-1 ${styles.sideBarMenuMob}`}>
      <Container>
        <div className={styles.burgerMenu}>
          <button onClick={toggleSidebar} className={styles.burgerButton}>{isOpen ? '✖' : '☰'}</button>
          <div className={styles.menuText}>MENU</div>
        </div>
      </Container>
      {isOpen && (
        <>
          <Container className='mx-0'>
            <SidebarMenu {...props} />
          </Container>
        </>
      )}
      <Container className='w-100'>
        <Advert />
      </Container>
    </Container>
  )
}

export default SideBar