import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BaseUrl } from "../App";
import NewsAnalysisContainer from "../components/pageComponents/NewsAnalysis/NewsAnalysisContainer";
import LoadingPage from "./LoadingPage";
import backBtnImg from '../images/backbutton.png';
import newsstyles from '../styles/News.module.css'
import { Link } from 'react-router-dom';


const NewsAnalysis = () => {
    const [isLoading,setLoading] = useState(true);
    const [article,setArticle] = useState([]);
    useEffect(()=>{
        axios.get(BaseUrl+'/get-news-analysis').then((response) =>{
            setArticle(response.data.data);
            setLoading(false);
        });
    },[])
    
    return (
        <div className="slideInAnimation">
            {/* {(isLoading === false) ? <div className={`${newsstyles.newsContainer}`}>
                    <div className="backButton">
                        <Link to={'/'}>
                            <img src={backBtnImg}></img>
                        </Link>
                    </div>
                    <div className="pageHeading">
                        <h1>News & Analysis</h1>
                    </div>
                    <NewsAnalysisContainer news = {article} />
                </div> : <LoadingPage />} */}
                <div className={`${newsstyles.newsContainer}`}>
                    <div className="backButton">
                        <Link to={'/'}>
                            <img src={backBtnImg}></img>
                        </Link>
                    </div>
                    <div className="pageHeading">
                        <h1>News & Analysis</h1>
                    </div>
                    <NewsAnalysisContainer news = {article} />
                </div>
        </div>
    );
}

export default NewsAnalysis;