import classes from "../../../styles/Login.module.css"
import { Link } from "react-router-dom";
import { useState,ChangeEvent, useEffect } from 'react';
import axios from "axios";
import { useLocation } from "react-router";
import { BaseUrl } from "../../../App";

const LoginContainer = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [isEmailCorrect,setIsEmailCorrect] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [wrongCredentials, setWrongCredentials] = useState({display: 'none', color: 'var(--mainColor)', fontWeight: 400, fontSize: '12px', paddingBottom: '20px'});
    const [errorEmailText,setErrorEmailText] = useState({display: 'none', color: 'red', fontSize: '12px'});

    const loginWithEnter = (event:any) => {
        if(event.key == "Enter"){
            LoginHandler(email,password);
        }
    }
    const isValidEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);  
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!isValidEmail(e.target.value)) {
            setIsEmailCorrect(false);
        } else {
            setIsEmailCorrect(true);
            setErrorEmailText({display: 'none', color: 'red', fontSize: '12px'});
        }
        setEmail(e.target.value);
    };
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setWrongCredentials({display: 'none', color: 'var(--mainColor)',fontWeight: 400, fontSize: '12px', paddingBottom: '20px'});
        setPassword(e.target.value);
    };
    const LoginHandler = (email:string, password:string) => {
        if(isEmailCorrect === true){
            axios.get(BaseUrl + "/viewer-login", {
                params: { email: email, password: password },
              }).then((response)=>{
                console.log(response.data);
                    if(response.data.data.needsVal === 1){
                        window.location.replace('/verify?token=' + response.data.data.validationToken);
                    } else {
                        const token = response.data.data.tokenType + ' ' + response.data.data.token.plainTextToken;
                        const subscribed = response.data.data.subscribed;
                        sessionStorage.setItem("token", token);
                        sessionStorage.setItem("subscribed", subscribed);
                        window.location.replace('/');
                    }
                    console.log(response.data);
              }).catch((error)=>{
                setWrongCredentials({display: 'block', color: 'var(--mainColor)',fontWeight: 400, fontSize: '12px', paddingBottom: '20px'});
              })
        }else {
            setErrorEmailText({display: 'block', color: 'red', fontSize: '12px'});
        }
    }                                                
    return (
        <div className={`${classes.Background}`}>
        <div className={`${classes.Container}`}>    
            <div className={`${classes.insideContainer}`}>
                <div className={`${classes.UpperText}`}>
                    LOG - IN
                </div>
                <div className={`${classes.information}`}>
                    <input  className={`${classes.inputStyle}`}placeholder="Email..." type="text" onKeyDown={loginWithEnter} value={email} onChange={handleChange}></input>
                    
                </div>
                <div style={errorEmailText}> To email δεν είναι έγκυρο</div>
                <div className={`${classes.information}`}>
                    <input  className={`${classes.inputStyle}`}placeholder="Εισάγετε Κωδικό Πρόσβασης..." type="password"  onKeyDown={loginWithEnter} onChange={handlePasswordChange}
                    ></input>   
                </div>
                <div style={wrongCredentials} className={classes.errorMessage}>Η διεύθυνση ή ο κωδικός είναι λάνθασμένος. <br/> Προσπαθήστε ξανά.</div>
                <button className={`${classes.loginStylev2}`} onClick={()=>{LoginHandler(email,password)}} >Enter</button>
                <div className={`${classes.signupv2}`}> Don't have an account? 
                    <Link className={`${classes.signupColored}`} to="/signup">Sign Up</Link>
                </div>
                <div className={`${classes.signupv2}`}> Forgot Your
                    <Link className={`${classes.signupColored}`} to="/forgot-password">Password?</Link>
                </div>
            </div>
        </div>
    </div>
);
}
export default LoginContainer;