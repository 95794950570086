import React from 'react'
import styles from '../../styles/main.module.css'
import facebook from '../../images/facebookW.png'
import { FacebookShareButton, LinkedinIcon, LinkedinShareButton } from 'react-share';
import { WhatsappShareButton } from 'react-share';
import { EmailShareButton, EmailIcon } from 'react-share';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import twitter from '../../images/twitter-wh.png'
import linkedin from '../../images/linkedin-wh.png'
import whatsup from '../../images/whatsapp-wh.png'
import email from '../../images/email-wh.png'


function Socials(props: any) {
  const {type, image, article} = props;
  const shareUrl = window.location.href;
  const title = document.title;
  const body = article.body;
  const preview = article.preview || '';
  if (type == 'facebook') {
    return (
        <div className={styles.socials}>
            <FacebookShareButton
                className='d-flex'
                url={shareUrl}
                quote={title}>
                <img src={image} width="20px"></img>
            </FacebookShareButton>
        </div>
      )
  } else if (type == 'whatsapp') {
    return (
        <div className={styles.socials}>
            <WhatsappShareButton
                className='d-flex'
                url={shareUrl}>
                <img src={whatsup} width="20px"></img>
            </WhatsappShareButton>
        </div>
      )
  } else if (type == 'email') {
    return (
        <div className={styles.socials}>
            <EmailShareButton
                className='d-flex'
                url={shareUrl}
                subject={title}
                body={body}>
                <img src={email} width="20px"></img>
            </EmailShareButton>
        </div>
      )
  } else if (type == 'linkedin') {
    const queryParams = { url: shareUrl };
    const linkString = new URLSearchParams(queryParams).toString();
    return (
        <div className={styles.socials}>
            <a href={`https://www.linkedin.com/sharing/share-offsite/?${linkString}`} target="_blank" className='share-icon'>
                <img src={linkedin} width="20px" className='share-img'></img>
            </a>
        </div>
      )
  } else if (type == 'twitter') {
    return (
        <div className={styles.socials}>
            <TwitterShareButton
                className='d-flex'
                url={shareUrl}
                title={title}>
                <img src={twitter} width="20px"></img>
            </TwitterShareButton>
        </div>
      )
  } else {
    return (<div></div>);
  }
}

export default Socials