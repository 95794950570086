import axios from "axios";
import { useEffect, useState } from "react";
import { BaseUrl } from "../../../App";
import InsideArticle from "../../generalComponents/InsideArticle";
import Row from "react-bootstrap/esm/Row";
import SideBar from "../../generalComponents/SideBar";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import { Helmet } from "react-helmet-async";

const AuthorsArticleContainer = (props:any) => {
    const [isLoading,setLoading] = useState(true);
    const [moreArticles, setMoreArticles] = useState([] as any);
    const [hasMoreArticles, setHasMoreArticles] = useState(true);
    const [page, setPage] = useState(1);
    const shareUrl = window.location.href;
    const title = document.title;
    const loadArticles = (pageNum = 1) => {
      axios.get(BaseUrl+'/get-tags-articles',{
          params: {
              tagId: props.material.tagId,
              openedId: props.openedId || props.material?.id,
              page: pageNum
          }
      }).then((response) => {
          setMoreArticles([...moreArticles, ...response.data.data.articles]);
          setHasMoreArticles(response.data.data.has_more_articles);
          isLoading && setLoading(false);
      });
    };
    const loadMore = () => {
      const nextPage = page + 1;
      setPage(nextPage);
      loadArticles(nextPage);
    };
    useEffect(()=> {
      loadArticles();
    },[props.openedId])
    if(isLoading === false){
      return (        
          <Container fluid className="h-100 px-lg-5 py-5 px-0">
              <Helmet>
                  <title>{'The Morning View | ' + props.material.title}</title>
              </Helmet>
              <Row className="container mx-0">
                  <Col lg={3} >
                      <SideBar {...props} />
                  </Col>
                  <Col lg={9} xs={12}>
                      <InsideArticle article={props.material} moreArticles={moreArticles} loadMore={loadMore} hasMoreArticles={hasMoreArticles} />
                  </Col>
              </Row>
          </Container>
        );
    }else{
        return <div>Loading...</div>;
    }
}
export default AuthorsArticleContainer;