import React, { useRef, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../App";
import styles from "../../styles/Comment.module.css";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Comment = (props: any) => {
  const {setCommentsLastUpdated} = props; 
  const { executeRecaptcha } = useGoogleReCaptcha();

  const articleId: Number = props.articleId || 0;
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [sendingMsg, setSendingMsg] = useState(false);
  const commentText = useRef<HTMLTextAreaElement>(null);
  const name = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const recaptchaRef: any = React.useRef();

  const handleSubmit = async () => {
    setSendingMsg(true);

    if (!executeRecaptcha) {
      setErrMsg("An error occurred.");
      return;
    } else {
      setErrMsg("");
    }

    const token = await executeRecaptcha('submit');
    
    try {
      if (commentText.current?.value === "") {
        setSuccessMsg("");
        setErrMsg("Το σχόλιό σας είναι απαραίτητο.");
        setSendingMsg(false);
        return;
      } else {
        setErrMsg("");
      }
      let commentTextValue = null;
      if (commentText.current && commentText.current.value) {
       commentTextValue = commentText.current?.value;
      }
      let emailTextValue = null;
      if (email.current && email.current.value) {
        emailTextValue = email.current?.value;
      }
      let nameTextValue = null;
      if (name.current && name.current.value) {
        nameTextValue = name.current?.value;
      }
      axios.post(BaseUrl + '/post-comment-web',
        {
            article_id: articleId,
            text: commentTextValue,
            name: nameTextValue,
            email: emailTextValue,
            google_recaptcha_response: token
        }).then((resp) => {
            if (resp.data?.success) {
                setSuccessMsg("Το σχόλιό σας καταχωρήθηκε επιτυχώς και θα εμφανιστεί μόλις εγκριθεί.");
                setTimeout(() => {
                  if (commentText.current && commentText.current.value) {
                    commentText.current.value = '';
                  }
                  if (name.current && name.current.value) {
                    name.current.value = '';
                  }
                  if (email.current && email.current.value) {
                   email.current.value = '';
                  }
                  setSendingMsg(false); 
                  setSuccessMsg("");
                }, 2000);
            } else {
                setSuccessMsg("");
                setErrMsg(resp.data?.message);
                setSendingMsg(false);            
            }
      }).catch((resp) => {
          setSuccessMsg("");
          setErrMsg(resp.response?.data?.message);
          setSendingMsg(false);
      });
    } catch (error) {
      setSuccessMsg("");
      setErrMsg("Παρακαλώ δοκιμάστε ξανά");
      setSendingMsg(false);
    }
  };

  return (
      <>
        <div className={styles.form}>
            <div className={styles.formLeftInner}>
                <input type="text" placeholder="Όνομα" ref={name}/>
                <input type="text" placeholder="Email" ref={email}/>
            </div>
            <div className={styles.formRightInner}>
                <textarea placeholder="Το σχόλιό σας..." ref={commentText}></textarea>
                <button type="submit" onClick={handleSubmit} disabled={sendingMsg}>Αποστολή</button>
                <div className={styles.formRightInner}><p>{errMsg}</p></div>
                <div className={styles.formRightInner}><p>{successMsg}</p></div>
            </div>
        </div>
      </>
  );
};

export default Comment;