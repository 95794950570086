import classes from "../../../styles/Login.module.css"
import { Link } from "react-router-dom";
import React from 'react';
import {useState} from 'react';

const MessageSuccess = () => {

    setTimeout(function() {
        window.location.replace('/login');
    }, 5000);
    const [counter, setCounter] = useState(5);
    setInterval(() => setCounter(counter - 1), 1000);

    return (
        
        <div className={`${classes.Background}`}>
        <div className={`${classes.Container}`}>

            <div className={`${classes.insideContainerEmailVer}`}>
            <div className={`${classes.UpperText}`}>Σας έχει σταλθεί κωδικός στο email.</div>
            <div className={`${classes.emailSentStyle} ${classes.information}`}>Θα γίνει ανακατεύθυνση αυτόματα εντός {counter} δευτερολέπτων.</div>
            <Link to="/login"><div className={`${classes.loginForgotPassStyle}`}> Πατήστε για άμεση σύνδεση</div></Link>

            </div>
        </div>
    </div>

)
}
export default MessageSuccess;