import { Helmet } from "react-helmet-async";
import { article } from "../HomePageComps/HomePageContainer";
import NewsAnalysisSingle from "./NewsAnalysisSingle";

const NewsAnalysisContainer = (props:any) => {
        return (
            <div>
                {props.news.map((article:article)=>{
                    return (
                        <div key={article.id}>
                            <NewsAnalysisSingle imagePath={article.imagePath} title={article.title} date={article.releaseDate} articleId = {article.id}/>
                        </div>
                    )
                }) }
            </div>
        );
    
}
export default NewsAnalysisContainer;