import styles from "../styles/LoadingPage.module.css"

const LoadingPage = () => {
    return (
        <div className={styles.mainStyle}>
            <div className={styles.ldsdualring}></div>
        </div>
    );
}

export default LoadingPage;