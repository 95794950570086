import classes from "../../../styles/Login.module.css"
import { useState,ChangeEvent, useEffect } from 'react';
import axios from "axios";
import { BaseUrl } from "../../../App";

const ForgotPassContainer = () => {
    const [email, setEmail] = useState('');
    const [isCorrectEmail,setIsCorrectEmail] = useState("");
    const [isEmailCorrect,setIsEmailCorrect] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const isValidEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
      };

      const forgotpassWithEnter = (event:any) => {
          if(event.key == "Enter"){
              handleDoneClick();
          }
      }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!isValidEmail(e.target.value)) {
            setIsEmailCorrect(false);
            setIsCorrectEmail("");
        } else {
            setIsEmailCorrect(true);
        }
        setEmail(e.target.value);
    };
    const checkMatch = () => {
        if ( isEmailCorrect === true){
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      };
    
      useEffect(() => {
        checkMatch();
      }, [email]); // Pass the dependencies that the effect needs

      const handleDoneClick = () => {
        if (!isDisabled) {
          setEmail("");
          axios.post(BaseUrl+"/forgot-password", {
            email:email,
          }).then((response) => {
            window.location.replace("/message-success");
          }).catch((error) => {
            console.log("error")
          })
        }
        else{
          setIsCorrectEmail("Συμπληρώστε σώστη μορφή Email");
        }
      };
    
    return (
        <div className={`${classes.Background}`}>
        <div className={`${classes.Container}`}>
            <div className={`${classes.insideContainerForgotPass}`}>
            <div className={`${classes.UpperText}`}>FORGOT PASSWORD</div>
                <div className={`${classes.textFlex} ${classes.information}`}>
                    <input  className={`${classes.inputStyle}`}  placeholder="Email"type="email"  value={email} onChange={handleChange} onKeyDown={forgotpassWithEnter}></input>
                </div>
                <div className={classes.required}  style={{display: isCorrectEmail ? 'block' : 'none' , marginBottom: isCorrectEmail ? "15px": "0px"}}>{isCorrectEmail}</div>
                <div className={`${classes.loginForgotPassStyle}`} onClick={handleDoneClick}> Send</div>
            </div>
        </div>
    </div>
)
}
export default ForgotPassContainer;