import React from "react";

export default function MyDate() {
    const current = new Date();
    const monthNames = [
        "Ιανουάριος", 
        "Φεβρουάριος", 
        "Μάρτιος", 
        "Απρίλιος", 
        "Μάιος", 
        "Ιούνιος",
        "Ιούλιος", 
        "Αύγουστος", 
        "Σεπτέμβριος", 
        "Οκτώβριος", 
        "Νοέμβριος", 
        "Δεκέμβριος"
        ];
    var days = ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'];
    var dayName = days[current.getDay()];

//   const date = `${monthNames[current.getMonth()]}/${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const date = `
  ${dayName},
  ${monthNames[current.getMonth()]}
  ${current.getDate()},
  ${current.getFullYear()}`;
  
  return (
    <div>
      <span>{date}</span>
    </div>
  );
}