import classes from "../../../styles/Login.module.css"   
import React, { useState, ChangeEvent,  useEffect } from 'react';
import axios from "axios";
import { BaseUrl } from "../../../App";

const EmailVerificationContainer = () => {
    const [code, setCode] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const length = code.length;

    const [errorvalidation, seterrorvalidation] = useState(classes.errorvalidation)

    const verifyWithEnter = (event:any) => {
      if(event.key == "Enter"){
          handleDoneClick();
      }
    }

    const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
      setCode(e.target.value);
      checkMatch();
      const result = e.target.value.replace( /[^0-9]/g, '');
      setCode(result);
      seterrorvalidation(classes.errorvalidation);
    };

    const checkMatch = () => {
      if (code !== "" && code.length>=6) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    };
  
    useEffect(() => {
      checkMatch();
    }, [code,]); // Pass the dependencies that the effect needs
  
    const handleDoneClick = () => {
      if (!isDisabled) {
        // Do something when code matches
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        console.log(token);
        axios.post(BaseUrl + '/email-verification', {
          token: token,
          code: code
        }).then( (response) => {
          if(response.data.success === true){
            window.location.replace('/verified')
          }
        }).catch((error) =>{
          seterrorvalidation(classes.errorvalidationTrue);
        })
      }
    };

    return (
        <div className={`${classes.Background}`}>
        <div className={`${classes.Container}`}>

            <div className={`${classes.insideContainerEmailVer}`}>
            <div className={`${classes.UpperText}`}>EMAIL VERIFICATION</div>
            <div className={`${classes.emailSentStyle}`}>Σας εχει σταλθεί ο κωδικος επιβεβαίωσης στο email<br/></div>
                <div className={`${classes.textFlex} ${classes.information}`}>
                    <input  className={`${classes.inputStyle}`}  placeholder="Insert 6 Digit Code" type="text" value={code} onChange={handleCodeChange} maxLength={6} onKeyDown={verifyWithEnter}></input>
                </div>
                <div className={errorvalidation}>Ο κωδικός δεν είναι έγκυρος</div>


               <div><button className={`${classes.loginInsertCodeStyle}`} disabled={isDisabled} onClick={handleDoneClick}> ENTER</button></div>
            </div> 
        </div>
    </div>

)
}
export default EmailVerificationContainer;