import React from 'react'
import googleplay from '../../images/androidImg.png'
import appstore from '../../images/iosImg.png'
import phone from '../../images/mobileBnr.png'
import styles from '../../styles/main.module.css'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'


function Promotion() {
  return (
    <div className={styles.banner}>
        <img src={phone} height="180px"></img>
        <div className="d-flex flex-column justify-content-around py-2">
            <div className="fs-lg-4 fs-6">
                Μείνετε ενημερωμένοι για τα πιο πρόσφατα νέα από τις
                07:00, στο Google Play & App Store.
            </div>
            <div className='d-flex flex-lg-row flex-column gap-3'>
            <a href="https://play.google.com/store/apps/details?id=com.viewmedia.themorningview&referrer=utm_source%3Dwebsite%26utm_medium%3Dbanner%26utm_term%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26utm_content%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26utm_campaign%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26anid%3Dadmob" target="_blank"><img src={googleplay} width='130px'></img></a>

            <a href="https://apps.apple.com/gr/app/the-morning-view-new/id6470270796?utm_source=website&utm_medium=banner&utm_campaign=News+Application+iOS+App+Store&utm_term=News+mobile+application+iOS+App+Store&utm_content=News+mobile+application+iOS+App+Store" target="_blank"><img src={appstore} width='130px'></img></a>
            </div>
        </div>
    </div>
  )
}

export default Promotion