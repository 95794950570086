import classes from "../../../styles/Eula.module.css";

const EulaContainer = () =>{
    return (
      <div
        className={`${classes.mainArticleContainer} ${classes.ContentStyle}`}
      >
        <div className={`${classes.headerStyle} `}>TERMS OF SERVICE / EULA</div>
        
        <div className={`${classes.eulaText} `}>
          <p><strong>TERMS OF SERVICE /&nbsp; &nbsp;END USER LICENSE AGREEMENT</strong></p>
          <p><strong>Last updated November 14, 2022</strong></p>

          <p>&nbsp;</p>

          <p>
            The Morning View is licensed to You (End-User) by VIEWMEDIA PC,
            located and registered at 106 Syngrou Ave, Athens , Attiki 11741,
            Greece (&quot;Licensor&quot;), for use only under the terms of this
            License Agreement.
          </p>

          <p>
            By downloading the Licensed Application from Apple&#39;s software
            distribution platform (&quot;App Store&quot;) and Google&#39;s
            software distribution platform (&quot;Play Store&quot;), and any
            update thereto (as permitted by this License Agreement), You indicate
            that You agree to be bound by all of the terms and conditions of this
            License Agreement, and that You accept this License Agreement. App
            Store and Play Store are referred to in this License Agreement as
            &quot;Services.&quot;
          </p>

          <p>
            The parties of this License Agreement acknowledge that the Services
            are not a Party to this License Agreement and are not bound by any
            provisions or obligations with regard to the Licensed Application,
            such as warranty, liability, maintenance and support thereof.
            VIEWMEDIA PC, not the Services, is solely responsible for the Licensed
            Application and the content thereof.
          </p>

          <p>
            This License Agreement may not provide for usage rules for the
            Licensed Application that are in conflict with the latest&nbsp;
            <a
              href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
              rel="noreferrer noopener"
              target="_blank"
            >
              Apple Media Services Terms and Conditions
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://play.google.com/intl/en_US/about/play-terms/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Google Play Terms of Service
            </a>
            &nbsp;(&quot;Usage Rules&quot;). VIEWMEDIA PC acknowledges that it had
            the opportunity to review the Usage Rules and this License Agreement
            is not conflicting with them.
          </p>

          <p>
            The Morning View when purchased or downloaded through the Services, is
            licensed to You for use only under the terms of this License
            Agreement. The Licensor reserves all rights not expressly granted to
            You. The Morning View is to be used on devices that operate with
            Apple&#39;s operating systems (&quot;iOS&quot; and &quot;Mac OS&quot;)
            or Google&#39;s operating system (&quot;Android&quot;).
          </p>

          <p>&nbsp;</p>

          <p><strong>TABLE OF CONTENTS</strong></p>

          <p>1. THE APPLICATION</p>

          <p>2. SCOPE OF LICENSE</p>

          <p>3. TECHNICAL REQUIREMENTS</p>

          <p>4. MAINTENANCE AND SUPPORT</p>

          <p>5. USE OF DATA</p>

          <p>6. USER-GENERATED CONTRIBUTIONS</p>

          <p>7. CONTRIBUTION LICENSE</p>

          <p>8. LIABILITY</p>

          <p>9. WARRANTY</p>

          <p>10. PRODUCT CLAIMS</p>

          <p>11. LEGAL COMPLIANCE</p>

          <p>12. CONTACT INFORMATION</p>

          <p>13. TERMINATION</p>

          <p>14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</p>

          <p>15. INTELLECTUAL PROPERTY RIGHTS</p>

          <p>16. APPLICABLE LAW</p>

          <p>17. MISCELLANEOUS</p>

          <p>&nbsp;</p>

          <p><strong>1. THE APPLICATION</strong></p>

          <p>
            The Morning View (&quot;Licensed Application&quot;) is a piece of
            software created to inform users on business and political daily news
            and analysis &mdash; and customized for iOS and Android mobile devices
            (&quot;Devices&quot;). It is used to News and views on politics and
            business.
          </p>

          <p>&nbsp;</p>

          <p><strong>2. SCOPE OF LICENSE</strong></p>

          <p>
            2.1 &nbsp;You are given a non-transferable, non-exclusive,
            non-sublicensable license to install and use the Licensed Application
            on any Devices that You (End-User) own or control and as permitted by
            the Usage Rules, with the exception that such Licensed Application may
            be accessed and used by other accounts associated with You (End-User,
            The Purchaser) via Family Sharing or volume purchasing.
          </p>

          <p>&nbsp;</p>

          <p>
            2.2 &nbsp;This license will also govern any updates of the Licensed
            Application provided by Licensor that replace, repair, and/or
            supplement the first Licensed Application, unless a separate license
            is provided for such update, in which case the terms of that new
            license will govern.
          </p>

          <p>&nbsp;</p>

          <p>
            2.3 &nbsp;You may not share or make the Licensed Application available
            to third parties (unless to the degree allowed by the Usage Rules, and
            with VIEWMEDIA PC&#39;s prior written consent), sell, rent, lend,
            lease or otherwise redistribute the Licensed Application.
          </p>

          <p>&nbsp;</p>

          <p>
            2.4 &nbsp;You may not reverse engineer, translate, disassemble,
            integrate, decompile, remove, modify, combine, create derivative works
            or updates of, adapt, or attempt to derive the source code of the
            Licensed Application, or any part thereof (except with VIEWMEDIA
            PC&#39;s prior written consent).
          </p>

          <p>&nbsp;</p>

          <p>
            2.5 &nbsp;You may not copy (excluding when expressly authorized by
            this license and the Usage Rules) or alter the Licensed Application or
            portions thereof. You may create and store copies only on devices that
            You own or control for backup keeping under the terms of this license,
            the Usage Rules, and any other terms and conditions that apply to the
            device or software used. You may not remove any intellectual property
            notices. You acknowledge that no unauthorized third parties may gain
            access to these copies at any time. If you sell your Devices to a
            third party, you must remove the Licensed Application from the Devices
            before doing so.
          </p>

          <p>&nbsp;</p>

          <p>
            2.6 &nbsp;Nothing in this license should be interpreted to restrict
            third-party terms. When using the Licensed Application, You must
            ensure that You comply with applicable third-party terms and
            conditions.
          </p>

          <p>&nbsp;</p>

          <p><strong>3. TECHNICAL REQUIREMENTS</strong></p>

          <p>&nbsp;</p>

          <p>
            3.1 &nbsp;The Licensed Application requires a firmware version 1.0.0
            or higher. Licensor recommends using the latest version of the
            firmware.
          </p>

          <p>&nbsp;</p>

          <p>
            3.2 &nbsp;Licensor attempts to keep the Licensed Application updated
            so that it complies with modified/new versions of the firmware and new
            hardware. You are not granted rights to claim such an update.
          </p>

          <p>&nbsp;</p>

          <p>
            3.3 &nbsp;You acknowledge that it is Your responsibility to confirm
            and determine that the app end-user device on which You intend to use
            the Licensed Application satisfies the technical specifications
            mentioned above.
          </p>

          <p>&nbsp;</p>

          <p>
            3.4 &nbsp;Licensor reserves the right to modify the technical
            specifications as it sees appropriate at any time.
          </p>

          <p>&nbsp;</p>

          <p><strong>4. MAINTENANCE AND SUPPORT</strong></p>

          <p>
            4.1 &nbsp;The Licensor is solely responsible for providing any
            maintenance and support services for this Licensed Application. You
            can reach the Licensor at the email address listed in the App Store or
            Play Store Overview for this Licensed Application.
          </p>

          <p>&nbsp;</p>

          <p>
            4.2 &nbsp;VIEWMEDIA PC and the End-User acknowledge that the Services
            have no obligation whatsoever to furnish any maintenance and support
            services with respect to the Licensed Application.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>5. USE OF DATA</strong></p>

          <p>
            You acknowledge that Licensor will be able to access and adjust Your
            downloaded Licensed Application content and Your personal information,
            and that Licensor&#39;s use of such material and information is
            subject to Your legal agreements with Licensor and Licensor&#39;s
            privacy policy:&nbsp;
            <a
              href="https://www.morningview.gr/app-privacy-policy"
              rel="noreferrer noopener"
              target="_blank"
            >
              https://www.morningview.gr/app-privacy-policy
            </a>
            .
          </p>

          <p>&nbsp;</p>

          <p>
            You acknowledge that the Licensor may periodically collect and use
            technical data and related information about your device, system, and
            application software, and peripherals, offer product support,
            facilitate the software updates, and for purposes of providing other
            services to you (if any) related to the Licensed Application. Licensor
            may also use this information to improve its products or to provide
            services or technologies to you, as long as it is in a form that does
            not personally identify you.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>6. USER-GENERATED CONTRIBUTIONS</strong></p>

          <p>
            The Licensed Application does not offer users to submit or post
            content. We may provide you with the opportunity to create, submit,
            post, display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or in the Licensed Application, including
            but not limited to text, writings, video, audio, photographs,
            graphics, comments, suggestions, or personal information or other
            material (collectively, &quot;Contributions&quot;). Contributions may
            be viewable by other users of the Licensed Application and through
            third-party websites or applications. As such, any Contributions you
            transmit may be treated in accordance with the Licensed Application
            Privacy Policy. When you create or make available any Contributions,
            you thereby represent and warrant that:
          </p>

          <p>&nbsp;</p>

          <p>
            1. The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </p>

          <p>
            2. You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Licensed Application, and other users of the Licensed
            Application to use your Contributions in any manner contemplated by
            the Licensed Application and this License Agreement.
          </p>

          <p>
            3. You have the written consent, release, and/or permission of each
            and every identifiable individual person in your Contributions to use
            the name or likeness or each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any manner
            contemplated by the Licensed Application and this License Agreement.
          </p>

          <p>4. Your Contributions are not false, inaccurate, or misleading.</p>

          <p>
            5. Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </p>

          <p>
            6. Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </p>

          <p>
            7. Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </p>

          <p>
            8. Your Contributions are not used to harass or threaten (in the legal
            sense of those terms) any other person and to promote violence against
            a specific person or class of people.
          </p>

          <p>
            9. Your Contributions do not violate any applicable law, regulation,
            or rule.
          </p>

          <p>
            10. Your Contributions do not violate the privacy or publicity rights
            of any third party.
          </p>

          <p>
            11. Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </p>

          <p>
            12. Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </p>

          <p>
            13. Your Contributions do not otherwise violate, or link to material
            that violates, any provision of this License Agreement, or any
            applicable law or regulation.
          </p>

          <p>&nbsp;</p>

          <p>
            Any use of the Licensed Application in violation of the foregoing
            violates this License Agreement and may result in, among other things,
            termination or suspension of your rights to use the Licensed
            Application.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>7. CONTRIBUTION LICENSE</strong></p>

          <p>
            You agree that we may access, store, process, and use any information
            and personal data that you provide following the terms of the Privacy
            Policy and your choices (including settings).
          </p>

          <p>&nbsp;</p>

          <p>
            By submitting suggestions of other feedback regarding the Licensed
            Application, you agree that we can use and share such feedback for any
            purpose without compensation to you.
          </p>

          <p>&nbsp;</p>

          <p>
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or representations
            in your Contributions provided by you in any area in the Licensed
            Application. You are solely responsible for your Contributions to the
            Licensed Application and you expressly agree to exonerate us from any
            and all responsibility and to refrain from any legal action against us
            regarding your Contributions.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>8. LIABILITY</strong></p>

          <p>
            8.1 &nbsp;Licensor takes no accountability or responsibility for any
            damages caused due to a breach of duties according to Section 2 of
            this License Agreement. To avoid data loss, You are required to make
            use of backup functions of the Licensed Application to the extent
            allowed by applicable third-party terms and conditions of use. You are
            aware that in case of alterations or manipulations of the Licensed
            Application, You will not have access to the Licensed Application.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>9. WARRANTY</strong></p>

          <p>
            9.1 &nbsp;Licensor warrants that the Licensed Application is free of
            spyware, trojan horses, viruses, or any other malware at the time of
            Your download. Licensor warrants that the Licensed Application works
            as described in the user documentation.
          </p>

          <p>&nbsp;</p>

          <p>
            9.2 &nbsp;No warranty is provided for the Licensed Application that is
            not executable on the device, that has been unauthorizedly modified,
            handled inappropriately or culpably, combined or installed with
            inappropriate hardware or software, used with inappropriate
            accessories, regardless if by Yourself or by third parties, or if
            there are any other reasons outside of VIEWMEDIA PC&#39;s sphere of
            influence that affect the executability of the Licensed Application.
          </p>

          <p>&nbsp;</p>

          <p>
            9.3 &nbsp;You are required to inspect the Licensed Application
            immediately after installing it and notify VIEWMEDIA PC about issues
            discovered without delay by email provided in&nbsp;
            <a
              href="https://codebeautify.org/htmlviewer#contact"
              rel="noreferrer noopener"
              target="_blank"
            >
              Contact Information
            </a>
            . The defect report will be taken into consideration and further
            investigated if it has been emailed within a period of 90 days after
            discovery.
          </p>

          <p>&nbsp;</p>

          <p>
            9.4 &nbsp;If we confirm that the Licensed Application is defective,
            VIEWMEDIA PC reserves a choice to remedy the situation either by means
            of solving the defect or substitute delivery.
          </p>

          <p>&nbsp;</p>

          <p>
            9.5 &nbsp;In the event of any failure of the Licensed Application to
            conform to any applicable warranty, You may notify the Services Store
            Operator, and Your Licensed Application purchase price will be
            refunded to You. To the maximum extent permitted by applicable law,
            the Services Store Operator will have no other warranty obligation
            whatsoever with respect to the Licensed Application, and any other
            losses, claims, damages, liabilities, expenses, and costs attributable
            to any negligence to adhere to any warranty.
          </p>

          <p>&nbsp;</p>

          <p>
            9.6 &nbsp;If the user is an entrepreneur, any claim based on faults
            expires after a statutory period of limitation amounting to twelve
            (12) months after the Licensed Application was made available to the
            user. The statutory periods of limitation given by law apply for users
            who are consumers.
          </p>

          <p>&nbsp; &nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>10. PRODUCT CLAIMS</strong></p>

          <p>
            VIEWMEDIA PC and the End-User acknowledge that VIEWMEDIA PC, and not
            the Services, is responsible for addressing any claims of the End-User
            or any third party relating to the Licensed Application or the
            End-User&rsquo;s possession and/or use of that Licensed Application,
            including, but not limited to:
          </p>

          <p>&nbsp;</p>

          <p>(i) product liability claims;</p>

          <p>&nbsp; &nbsp;</p>

          <p>
            (ii) any claim that the Licensed Application fails to conform to any
            applicable legal or regulatory requirement; and
          </p>

          <p>&nbsp;</p>

          <p>
            (iii) claims arising under consumer protection, privacy, or similar
            legislation, including in connection with Your Licensed
            Application&rsquo;s use of the HealthKit and HomeKit.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>11. LEGAL COMPLIANCE</strong></p>

          <p>
            You represent and warrant that You are not located in a country that
            is subject to a US Government embargo, or that has been designated by
            the US Government as a &quot;terrorist supporting&quot; country; and
            that You are not listed on any US Government list of prohibited or
            restricted parties.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>12. CONTACT INFORMATION</strong></p>

          <p>
            For general inquiries, complaints, questions or claims concerning the
            Licensed Application, please contact:
          </p>

          <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

          <p>Anastasia Papaioannou</p>

          <p>106 Syngrou Ave</p>

          <p>Athens , Attiki 11741</p>

          <p>Greece</p>

          <p>
            <a href="mailto:themorningviewapp@gmail.com">
              themorningviewapp@gmail.com
            </a>
          </p>

          <p>&nbsp;</p>

          <p><strong>13. TERMINATION</strong></p>

          <p>
            The license is valid until terminated by VIEWMEDIA PC or by You. Your
            rights under this license will terminate automatically and without
            notice from VIEWMEDIA PC if You fail to adhere to any term(s) of this
            license. Upon License termination, You shall stop all use of the
            Licensed Application, and destroy all copies, full or partial, of the
            Licensed Application.
          </p>

          <p>&nbsp; &nbsp; &nbsp;&nbsp;</p>

          <p>&nbsp;</p>

          <p><strong>14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</strong></p>

          <p>
            VIEWMEDIA PC represents and warrants that VIEWMEDIA PC will comply
            with applicable third-party terms of agreement when using Licensed
            Application.
          </p>

          <p>&nbsp;</p>

          <p>
            In Accordance with Section 9 of the &quot;Instructions for Minimum
            Terms of Developer&#39;s End-User License Agreement,&quot; both Apple
            and Google and their subsidiaries shall be third-party beneficiaries
            of this End User License Agreement and &mdash; upon Your acceptance of
            the terms and conditions of this License Agreement, both Apple and
            Google will have the right (and will be deemed to have accepted the
            right) to enforce this End User License Agreement against You as a
            third-party beneficiary thereof.
          </p>

          <p>&nbsp;</p>

          <p><strong>15. INTELLECTUAL PROPERTY RIGHTS</strong></p>

          <p>
            VIEWMEDIA PC and the End-User acknowledge that, in the event of any
            third-party claim that the Licensed Application or the End-User&#39;s
            possession and use of that Licensed Application infringes on the third
            party&#39;s intellectual property rights, VIEWMEDIA PC, and not the
            Services, will be solely responsible for the investigation, defense,
            settlement, and discharge or any such intellectual property
            infringement claims.
          </p>

          <p>&nbsp;</p>

          <p><strong>16. APPLICABLE LAW</strong></p>

          <p>
            This License Agreement is governed by the laws of Greece excluding its
            conflicts of law rules.
          </p>

          <p>&nbsp;</p>

          <p><strong>17. MISCELLANEOUS</strong></p>

          <p>
            17.1 &nbsp;If any of the terms of this agreement should be or become
            invalid, the validity of the remaining provisions shall not be
            affected. Invalid terms will be replaced by valid ones formulated in a
            way that will achieve the primary purpose.
          </p>

          <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>

          <p>
            17.2 &nbsp;Collateral agreements, changes and amendments are only
            valid if laid down in writing. The preceding clause can only be waived
            in writing.
          </p>
        </div>
      </div>
    );
}
export default EulaContainer;