import classes from "../../../styles/Signup.module.css"
import { useState,ChangeEvent, useEffect } from 'react';
import axios from "axios";
import { BaseUrl } from "../../../App";
import { Link } from "react-router-dom";

const SignUpContainer = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredv2, setIsHoveredv2] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isEmailCorrect,setIsEmailCorrect] = useState(false);
    const [colorChange,setColorChange] = useState(false);
    const regexp = new RegExp("(?=.*[A-Z])(?=.*\\d)");
    const [email, setEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newMail,setNewMail] = useState('');
    const [username, setUsername] = useState('');
    const [SignUpPassword, setSignUpPassword] = useState('');

    // ALERTS
    const [requiredUsename, setrequiredUsename] = useState(classes.required);
    const [requiredEmail, setrequiredEmail] = useState(classes.required);
    const [validateEmail, setvalidateEmail] = useState(classes.validemail);
    const [requiredPassword, setrequiredPassword] = useState(classes.required);
    const [validPassword, setvalidPassword] = useState(classes.required);
    const [validConfirmPassword, setvalidConfirmPassword] = useState(classes.validConfirmPassword);
    const [emailExists, setemailExists] = useState('');

    const loginWithEnter = (event:any) => {
        if(event.key == "Enter"){
            handleDoneClick(username,email,SignUpPassword,confirmPassword);
        }
    }

    const handleClick = () => {
        setIsClicked(!isClicked);
    };
    const isValidEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);  
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!isValidEmail(e.target.value)) {
            setIsEmailCorrect(false);
        } else {
            setIsEmailCorrect(true);
        }
        setNewMail(e.target.value);
        setvalidateEmail(classes.validemail);
        setrequiredEmail(classes.required);
    };
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSignUpPassword(e.target.value);
        checkMatch();
        setrequiredPassword(classes.required);
        setvalidPassword(classes.required);
    };
    const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      setConfirmPassword(e.target.value);
      checkMatch();
      setvalidConfirmPassword(classes.validConfirmPassword);
    };
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
        checkMatch();
        setrequiredUsename(classes.required);
    };
    const checkMatch = () => {
      if (SignUpPassword !== "" && confirmPassword !== "" && SignUpPassword === confirmPassword && length > 7 && regexp.test(SignUpPassword) && isEmailCorrect === true && username !==""){
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    };
  
    useEffect(() => {
      checkMatch();
    }, [SignUpPassword, confirmPassword, newMail, username]);
    
    const handleDoneClick = (username:any, email:any, SignUpPassword:any, confirmPassword:any) => {
      if (!isDisabled) {
        axios.post(BaseUrl+'/register-viewer',{
            email: newMail,
            password: SignUpPassword,
            confirmPass: confirmPassword,
            nickname: username
        }).then((response)=>{
            window.location.replace('/verify?token='+response.data.data.validationToken)
        }).catch((error) => {
            setemailExists(error.response.data.message);
        })
      }
      else{
        if(username==""){
            setrequiredUsename(classes.requiredTrue);
            console.log("δεν υπάρχει username");
        } else if (username !=="") {
            setrequiredUsename(classes.required);
        }
        if(newMail==""){
            setrequiredEmail(classes.requiredTrue);
            console.log("δεν υπάρχει email")
        } else if (newMail !=="") {
            setrequiredEmail(classes.required);
        }
        if(isEmailCorrect !== true && newMail !==""){
            setvalidateEmail(classes.validemailTrue);
            setrequiredEmail(classes.required);
        } else {
            setvalidateEmail(classes.validemail);
        }
        if(SignUpPassword==""){
            setrequiredPassword(classes.requiredTrue);
            console.log("δεν υπάρχει κωδικός")
            setvalidPassword(classes.required);
        } else if(SignUpPassword !=="") {
            setrequiredPassword(classes.required);
        }
        if (length < 8 && !regexp.test(SignUpPassword) && SignUpPassword !==""){
            setvalidPassword(classes.requiredTrue);
            setrequiredPassword(classes.required);
            console.log("Einai katw apo 8");
        } else {
            console.log("Einai panw apo 8");
        }
        if (SignUpPassword !== confirmPassword){
            setvalidConfirmPassword(classes.validConfirmPasswordTrue);
            console.log("den tairiazoun");
        }
      }
    };
    
    const length = SignUpPassword.length;
    
    return (

    <div className={`${classes.Background}`}>
        <div className={`${classes.Container}`}  style={{ transform: `scale(${isHovered ? 1.1 : 1})`, filter: `blur(${isHoveredv2 ? '2px' : '0px'})`  }}>    
            <div className={`${classes.insideContainer}`}>
                <div className={`${classes.UpperText}`}>
                    SIGN - UP
                </div>
                <div style={{color:'var(--mainColor)', textDecoration: 'underline'}}>{emailExists}</div>
                <div className={`${classes.information}`}>
                    <input  className={`${classes.inputStyle}`}placeholder="Όνομα Χρήστη..." type="text" maxLength={20} value={username} onChange={handleNameChange} onKeyDown={loginWithEnter}></input>
                    <div className={`${requiredUsename}`}>Το πεδίο είναι υποχρεωτικό. <span style={{color: 'var(--mainColor)'}}>*</span></div>
                </div>
                <div className={`${classes.information}`}>
                    <input  className={`${classes.inputStyle}`}placeholder="Εισαγωγή Email..." type="email" value={newMail} onChange={handleChange} onKeyDown={loginWithEnter}></input>
                    <div className={requiredEmail}>Το πεδίο είναι υποχρεωτικό. <span style={{color: 'var(--mainColor)'}}>*</span></div>
                    <div className={validateEmail}>Το email δεν είναι έγκυρο.</div>
                </div>
                <div className={`${classes.information}`}>
                    <input  className={`${classes.inputStyle}`}placeholder="Εισάγετε Κωδικό Πρόσβασης..." type="text" onChange={handlePasswordChange}  onKeyDown={loginWithEnter}></input>   
                    <div className={requiredPassword}>Το πεδίο είναι υποχρεωτικό. <span style={{color: 'var(--mainColor)'}}>*</span></div>
                </div>
                <div className={`${validPassword} ${classes.passwordinfo}`} style={{ color: colorChange ? "#E2120B" : "#fff" }} >
                    Απαιτήσεις κωδικού πρόσβασης:
                    Τουλάχιστον ένας ειδικός χαρακτήρας,
                    Τουλάχιστον ένα κεφαλαίο γράμμα,
                    Τουλάχιστον ένας αριθμός [0-9],
                    Τουλάχιστον 8 χαρακτήρες
                </div>
                <div className={`${classes.information}`}>
                    <input  className={`${classes.inputStyle}`}placeholder="Επαλήθευση Κωδικού..." type="password" onChange={handleConfirmPasswordChange}  onKeyDown={loginWithEnter}></input>
                    <div className={validConfirmPassword}>Οι κωδικοί δεν ταιριάζουν.</div>
                </div>
                <button className={`${classes.loginStylev2}`} onClick={()=>{handleDoneClick(username,email,SignUpPassword,confirmPassword)}}>ENTER</button>
                <div className={`${classes.signupv2}`}> Already have an account? 
                    <Link className={`${classes.signupColored}`} to="/login">Log In</Link>
                </div>
            </div>
        </div>
    </div>
);
}
export default SignUpContainer;