import { useNavigate } from 'react-router';
import styles from '../../styles/main.module.css';

function BottomBar(props: any) {
  const navigate = useNavigate();
  return (
    <div>
        <div className={styles.bottomBarStyle}>
            <button className={styles.bottomBarButton} onClick={() => {
                navigate('/about');
            }}> 
                About
            </button>
            <div> 
                -
            </div>
            <button className={styles.bottomBarButton} onClick={() => {
                navigate('/app-privacy-policy');
            }}> 
                App Privacy Policy
            </button>
            <div> 
                -
            </div>
            <button className={styles.bottomBarButton} onClick={() => {
                navigate('/eula');
            }}>
                App terms EULA
            </button>
        </div>  
        <div className={styles.bottomBarStyle}>
            <span>The MorningView &copy; 2023 By <a className={styles.footerLink} href="https://citrine.gr/" target="_blank">Citrine</a></span>
        </div>  
    </div>
  )
}

export default BottomBar