import CommentRow from './CommentRow';
import React, {useEffect, useState} from 'react';
import { BaseUrl } from '../../App';
import axios from 'axios';

function Comments(props: any) {
    const {articleId} = props;
    const [comments, setComments] = useState([]);
    const [limit, setLimit] = useState(5);
    const [hasMorePages, setHasMorePages] = useState(false);
    
    const fetchComments = (limit: Number) => {
        axios.get(BaseUrl + "/get-article-comments", {
            params: {
                article_id: articleId,
                limit: limit
            }
          }).then((response: any) => {
            if (response.data.success) {
                setComments(response.data.data || []);
            }
          }).catch((err: any)=> {});
    };

    useEffect(() => {
        fetchComments(limit);
    }, [limit]);
    
    if (!comments.length) {
        return null;
    }

    return (
        <>
            {
                comments.map((comment: any) => {
                    return <CommentRow comment={comment} key={comment.id} />
                })
            }
            <div id="loadMoreComments" style={{textAlign: "center"}}>
                <button style={{
                    background: "linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%)",
                    color: "var(--whiteFont)",
                    border: "none",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    width: "max-content",
                    marginTop: "23px"}} onClick={() => setLimit(limit + 1)}>Περισσότερα...</button>
            </div>
        </>
    )
}

export default Comments;