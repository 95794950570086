import styles from "../../../styles/main.module.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../../App";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import mainBanner from "../../../images/mobileBnr.png";
import android from "../../../images/androidImg.png";
import ios from "../../../images/iosImg.png";
import adv1 from "../../../images/dei.gif";
import adv2 from "../../../images/intracat.gif";
import { article, tag } from "../HomePageComps/HomePageContainer";
import BlogSingle from "./BlogSingle";
import Banner from "../../../pages/Banner";
import { Helmet } from "react-helmet-async";
import SideBar from "../../generalComponents/SideBar";
import Promotion from "../../generalComponents/Promotion";
import ListedArticles from "../../generalComponents/ListedArticles";
import NewsAnalysis from "../../pageComponents/HomePageComps/NewsAndAnalysis";

const BlogContainer = (props:any) => {
    const {theTagId, loadMore, hasMoreArticles, newsAndAnalysisFrontPage, bottomFeaturedArticles} = props;
    if(!theTagId){
      // Home page
      return (
        <Row className="container-fluid mx-0">
         <Col lg={4} >
              <SideBar tags={props.tags} selectedTagId={theTagId}/>
          </Col>
          <Col lg={8} xs={12}>
            <NewsAnalysis newsAndAnalysisFrontPage={newsAndAnalysisFrontPage} bottomFeaturedArticles={bottomFeaturedArticles} />
          </Col>
        </Row>
      )
    } else {
      // Article listing
      return (
        <Row className="container-fluid mx-0">
        <Col lg={4} >
             <SideBar tags={props.tags} selectedTagId={theTagId} />
         </Col>
         <Col lg={8} xs={12} className="d-flex flex-column align-items-center">
           <Promotion />
           <ListedArticles articles={props.blog} />
           <button className={styles.loadMoreButton} onClick={() => { loadMore(); }} disabled={hasMoreArticles ? false : true}>Περισσότερα...</button>
         </Col>
       </Row>
      );
    }

    
}
export default BlogContainer;