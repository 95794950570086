import AuthorsListedArticle from './AuthorsListedArticle';

function AuthorsListedArticles(props: any) {
    const {articles} = props;
    return (
        articles.map((article: any) => {
            return <AuthorsListedArticle article={article} key={article.id} />
        })
    )
}

export default AuthorsListedArticles