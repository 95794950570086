import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import styles from '../../styles/main.module.css'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'


function LineWithDot(props: { customstyle: any }) {
  const {customstyle} = props;
  return (
    <div className={styles.lineWithDot} >
        <div className={styles.line} style={customstyle}></div>
        <div className={styles.dot}></div>
    </div>
  )
}

export default LineWithDot