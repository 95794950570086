import classes from "../../../styles/Subscription.module.css";
import checked from "../../../images/checked.png"

const SubscriptionContainer = () =>{
    return (
        <div className={`${classes.backgroundStyle}`}>
            <div className={`${classes.mainContainer}`}>
                <div className={`${classes.mainContainerContent}`}> 
                    <div className={`${classes.overallPrice}`}>
                        <div className={`${classes.price}`}> &euro;8.99</div><div className={`${classes.month}`} >/μηνα</div>
                    </div>
                    <div className={`${classes.register}`}>Εγγραφείτε ως συνδρομητής στο MorningView και αποκτήστε: </div>
                    <div className={`${classes.toGet}`}> <img className={`${classes.padded}`} src={checked} width="20px"/>Έγκυρη και ολοκληρωμένη ενημέρωση κάθε πρωί στις 6:00</div>
                    <div className={`${classes.toGet}`}> <img className={`${classes.padded}`} src={checked} width="20px"/>Έκτακτες ενημερώσεις &#40;breaking news&#41; και alerts κατά τη διάρκεια της ημέρας</div>
                    <div className={`${classes.toGet}`}> <img className={`${classes.padded}`} src={checked} width="20px"/>Πρόσβαση σε επιπλέον, premium περιεχόμενο</div>
                    <div><button className={`${classes.joinUs}`}>Γίνετε Συνδρομητής</button></div>
                    <div><button className={`${classes.maybeAfter}`}>Ισως αργότερα</button></div>
                </div>
            </div>
        </div>
    )
}
export default SubscriptionContainer;