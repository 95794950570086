import ListedArticle from './ListedArticle';

function ListedArticles(props: any) {
    const {articles} = props;
    return (
        articles.map((article: any) => {
            return <ListedArticle article={article} key={article.id} />
        })
    )
}

export default ListedArticles