import { useNavigate } from "react-router";
import ChangePassContainer from "../components/pageComponents/ChangePass/ChangePassContainer";
import HomePage from "./HomePage";

const ChangePassword = () => {
    const token = sessionStorage.getItem('token');
    if(token === null || token == undefined){
        return <HomePage />
    }else {
        return(
            <div>
                <ChangePassContainer/>
            </div>
        )
    }
}

export default ChangePassword;