import React from 'react'
import { useNavigate } from 'react-router';
import styles from '../../styles/main.module.css'

function SideBarButton(props: any)  {
  const navigate = useNavigate();
  const {id, name, selectedTagId} = props;
  const buttonClass = selectedTagId && selectedTagId == id ? `${styles.SideBarButtonActive}` : `${styles.SideBarButton}`;
  const idToSlug = (id: any) => { 
    if (id == '1') {
      return 'apeleutheros';
    } else if (id == '2') {
      return 'gamechanger';
    } else if (id == '3') {
      return 'sketch';
    } else if (id == '4') {
      return 'news-and-analysis';
    } else if (id == '5') {
      return 'marketmaker';
    }
    return '';
  };
  return (
    <button className={`${buttonClass} d-lg-flex`} onClick={() => {
      navigate('/categories/' + idToSlug(id), {state: {tagId: id}});
    }}>{name}</button>
  )
}

export default SideBarButton