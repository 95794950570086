import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import SideBarButton from './SideBarButton'
import { Link } from "react-router-dom"
import styles from '../../styles/main.module.css'

function SideBarMenu(props: any) {
  const {tags, selectedTagId} = props;
  return (
    <div className={`${styles.homeCategoriesMob} d-flex flex-column gap-3 w-100`}>
      {
            tags.map((tag: any) => {
              return (
                  <SideBarButton key={tag.id} id={tag.id} name={tag.description} selectedTagId={selectedTagId} />
              );
            })
        }
    </div>
  )
}

export default SideBarMenu