import classes from "../../../styles/Login.module.css"
import { Link, useLocation, useParams } from "react-router-dom";
import React, { useState, ChangeEvent,  useEffect } from 'react';
import axios from "axios";
import { BaseUrl } from "../../../App";
import visible from '../../../images/visible.png'

const PasswordResetContainer = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCorrectPass, setIsCorrectPass] = useState("")
  const [isCorrectConfirmPass, setIsCorrectConfirmPass] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [colorChange,setColorChange] = useState(false);
  const length = password.length;
  const regexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)");

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const urltoken = search.get('token');

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setIsCorrectPass("");
    checkMatch();
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    setIsCorrectConfirmPass("");
    checkMatch();
  };

  const checkMatch = () => {
    if (password !== "" && confirmPassword !== "" && password === confirmPassword && length > 7 && regexp.test(password) ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    checkMatch();
  }, [password, confirmPassword]); // Pass the dependencies that the effect needs

  const handleDoneClick = (password:any, confirmPassword:any) => {
    if (!isDisabled) {
      setPassword("");
      setConfirmPassword("");
      axios.post(BaseUrl+'/reset-password', {
        token:urltoken,
        password:password
      }).then((response) => {
        if(response.data.success === true){
          window.location.replace('/login')
        }
      }).catch((error) =>{
        alert('error');
        setIsCorrectPass("");
        setIsCorrectConfirmPass("");
      })
    }
    else{
      if(password != confirmPassword){
        setIsCorrectConfirmPass("Οι κώδικοι διαφέρουν");
      }
      else if(password == "" && length < 7 && !regexp.test(password) && confirmPassword ==""){
        setIsCorrectPass("Το πεδίο είναι υποχρεωτικό");
        setIsCorrectConfirmPass("Το πεδίο είναι υποχρεωτικό");
    }
    else if(!regexp.test(password)){
      setIsCorrectPass("Συμπληρώστε έγκυρη μορφή κωδικού πρόσβασης");
      setIsCorrectConfirmPass("");
    }
    }
  };


  
    return (

        <div className={`${classes.Background}`}>
        <div className={`${classes.Container}`}>
            <div className={`${classes.insideContainerEmailVer}`}>
            <div className={`${classes.UpperText}`}>RESET PASSWORD</div>
                <div className={`${classes.textFlex} ${classes.information}`}>

                    <input  className={`${classes.inputStyle}`} type="text" placeholder="New password"  onChange={handlePasswordChange}></input>

                </div>
                <div className={classes.required}  style={{display: isCorrectPass ? 'block' : 'none' }}>{isCorrectPass}</div>

                <div className={`${classes.textFlex} ${classes.information}`} >
                  
                    <input  className={`${classes.inputStyle}`} type="password" placeholder="Confirm Password"  onChange={handleConfirmPasswordChange}></input>

                </div>
                <div className={classes.required}  style={{display: isCorrectConfirmPass ? 'block' : 'none' , marginBottom: isCorrectConfirmPass ? '15px' :'0px ' }}>{isCorrectConfirmPass}</div>
               <button className={`${classes.loginPasswordResetedStyle}`} onClick={()=>{handleDoneClick(password,confirmPassword)}}>ENTER</button>
            </div>
        </div>
    </div>
);
}
export default PasswordResetContainer
    