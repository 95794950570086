import EulaContainer from "../components/pageComponents/EulaComps/EulaContainer";
import classes from "../../../styles/EulaContainer.module.css";
import { Helmet } from "react-helmet-async";
const Eula = () => {
    return (
        <div>
            <Helmet>
                <title>{'The Morning View | Terms of Service / EULA'}</title>
            </Helmet>
            <EulaContainer></EulaContainer>
        </div>
    )
}
export default Eula;