
function CommentRow(props: any) {
    const {comment} = props;
    let username = null;
    if (comment.comment_email && comment.comment_email != '') {
        username = comment.comment_email;
    } else if (comment.comment_name && comment.comment_name != '') {
        username = comment.comment_name;
    } else if (comment.username && comment.username != '') {
        username = comment.username;
    }
    return (
        <div style={{marginBottom: "30px"}}>
            <p style={{padding: "0px", margin: "0px", fontSize: "14px"}}>Από: {username}, στις: {comment.created_at}</p>
            <p style={{paddingLeft: "10px"}}>{comment.text}</p>
        </div>
    )
}

export default CommentRow;