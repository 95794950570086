import { Helmet } from "react-helmet-async";
import classes from "../../../styles/Privacy.module.css";

const PrivacyContainerGreek = () =>{
    return (
      <div
        className={`${classes.mainArticleContainer} ${classes.ContentStyle}`}
      >
        <Helmet>
            <title>{'The Morning View | Privacy Policy'}</title>
        </Helmet>
        <div className={`${classes.headerStyle}`}>
          Privacy Policy - MorningView
        </div>
        <div className={`${classes.privacyText}`}>
            <p>
                <strong>
                
                    Ενημερώθηκε στις 23-08-2022
                
                </strong>
            </p>
            <p>
                
                Το MorningView («εμείς», «μας» ή «εμείς») δεσμεύεται να προστατεύει το απόρρητό σας. 
                Αυτή η Πολιτική Απορρήτου εξηγεί πώς συλλέγονται, χρησιμοποιούνται και αποκαλύπτονται οι προσωπικές σας πληροφορίες από το The MorningView.
                
            </p>
            <p>
                
                Αυτή η Πολιτική Απορρήτου ισχύει για τον ιστότοπό μας και τους συσχετιζόμενους υποτομείς (συλλογικά, την «Υπηρεσία» μας) παράλληλα με την εφαρμογή μας, The MorningView. 
                Με την πρόσβαση ή τη χρήση της Υπηρεσίας μας, δηλώνετε ότι έχετε διαβάσει, κατανοήσει και συμφωνήσει με τη συλλογή, αποθήκευση, χρήση και αποκάλυψη των προσωπικών σας στοιχείων όπως περιγράφεται στην παρούσα Πολιτική Απορρήτου και στους Όρους Παροχής Υπηρεσιών μας.&nbsp;
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Ορισμοί και βασικοί όροι
                
                </strong>
            </p>
            <p>
                
                Για να εξηγηθούν τα πράγματα όσο το δυνατόν πιο ξεκάθαρα σε αυτήν την Πολιτική Απορρήτου, κάθε φορά που γίνεται αναφορά σε οποιονδήποτε από αυτούς τους όρους, ορίζονται αυστηρά ως:
                
            </p>
            <ul>
                <li>
                <p>
                    
                    Cookie: μικρή ποσότητα δεδομένων που δημιουργείται από έναν ιστότοπο και αποθηκεύεται από το πρόγραμμα περιήγησής σας. 
                    Χρησιμοποιείται για την αναγνώριση του προγράμματος περιήγησής σας, την παροχή αναλυτικών στοιχείων, την απομνημόνευση πληροφοριών σχετικά με εσάς, όπως η προτίμηση γλώσσας ή τα στοιχεία σύνδεσης.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Εταιρεία: όταν αυτή η πολιτική αναφέρει «Εταιρεία», «εμείς», «εμείς» ή «δική μας», αναφέρεται στο VIEWMEDIA PC , Αλεξάνδρου Παναναστασίου 38, Αθήνα 15451 που είναι υπεύθυνο για τις πληροφορίες σας σύμφωνα με την παρούσα Πολιτική Απορρήτου.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Χώρα: όπου εδρεύουν το The MorningView ή οι ιδιοκτήτες/ιδρυτές του The MorningView, σε αυτήν την περίπτωση είναι η Ελλάδα
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Πελάτης: αναφέρεται στην εταιρεία, τον οργανισμό ή το άτομο που εγγράφεται για να χρησιμοποιήσει την Υπηρεσία The MorningView για τη διαχείριση των σχέσεων με τους καταναλωτές ή τους χρήστες των υπηρεσιών σας.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Συσκευή: οποιαδήποτε συσκευή συνδεδεμένη στο Διαδίκτυο, όπως τηλέφωνο, tablet, υπολογιστής ή οποιαδήποτε άλλη συσκευή που μπορεί να χρησιμοποιηθεί για να επισκεφθείτε το MorningView και να χρησιμοποιήσετε τις υπηρεσίες.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Διεύθυνση IP: Σε κάθε συσκευή που είναι συνδεδεμένη στο Διαδίκτυο εκχωρείται ένας αριθμός που είναι γνωστός ως διεύθυνση πρωτοκόλλου Διαδικτύου (IP). 
                    Αυτοί οι αριθμοί συνήθως εκχωρούνται σε γεωγραφικά τμήματα. 
                    Μια διεύθυνση IP μπορεί συχνά να χρησιμοποιηθεί για τον προσδιορισμό της τοποθεσίας από την οποία μια συσκευή συνδέεται στο Διαδίκτυο.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Προσωπικό: αναφέρεται σε εκείνα τα άτομα που απασχολούνται από το The MorningView ή είναι με σύμβαση για την εκτέλεση μιας υπηρεσίας για λογαριασμό ενός από τα μέρη.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Προσωπικά Δεδομένα: οποιαδήποτε πληροφορία που άμεσα, έμμεσα ή σε σχέση με άλλες πληροφορίες — συμπεριλαμβανομένου ενός προσωπικού αριθμού αναγνώρισης — επιτρέπει την αναγνώριση ή την ταυτοποίηση ενός φυσικού προσώπου.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Υπηρεσία: αναφέρεται στην υπηρεσία που παρέχεται από το Τhe MorningView όπως περιγράφεται στους σχετικούς όρους (εάν υπάρχει) και σε αυτήν την πλατφόρμα.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Υπηρεσία τρίτων: αναφέρεται σε διαφημιστές, χορηγούς διαγωνισμών, συνεργάτες προώθησης και μάρκετινγκ και άλλους που παρέχουν το περιεχόμενό μας ή των οποίων τα προϊόντα ή οι υπηρεσίες πιστεύουμε ότι μπορεί να σας ενδιαφέρουν.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Ιστοσελίδα: Ο ιστότοπος του The MorningView, ο οποίος είναι προσβάσιμος μέσω αυτής της διεύθυνσης URL:&nbsp; 
                    
                    <a href="https://www.morningview.gr/" target="_blank">
                    
                        https://www.morningview.gr/
                    
                    </a>
                </p>
                </li>
                <li>
                <p>
                    
                    Εσείς: ένα πρόσωπο ή μια οντότητα που είναι εγγεγραμμένη στο The MorningView για χρήση των Υπηρεσιών.
                    
                </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Πληροφορίες που συλλέγονται αυτόματα
                
                </strong>
            </p>
            <p>
                
                Υπάρχουν ορισμένες πληροφορίες, όπως η διεύθυνση πρωτοκόλλου Διαδικτύου (IP) ή/και τα χαρακτηριστικά του προγράμματος περιήγησης και της συσκευής — συλλέγονται αυτόματα όταν επισκέπτεστε την πλατφόρμα μας. 
                Αυτές οι πληροφορίες μπορούν να χρησιμοποιηθούν για τη σύνδεση του υπολογιστή σας στο Διαδίκτυο. 
                Άλλες πληροφορίες που συλλέγονται αυτόματα θα μπορούσαν να είναι στοιχεία σύνδεσης, διεύθυνση ηλεκτρονικού ταχυδρομείου, κωδικός πρόσβασης, υπολογιστής και πληροφορίες σύνδεσης, όπως τύποι και εκδόσεις προσθηκών προγράμματος περιήγησης και ρύθμιση ζώνης ώρας, λειτουργικά συστήματα και πλατφόρμες, ιστορικό αγορών (μερικές φορές συγκεντρώνουμε παρόμοιες πληροφορίες από άλλοι Χρήστες), την πλήρη ροή κλικ του Uniform Resource Locator (URL) προς, μέσω και από τον Ιστότοπό μας που μπορεί να περιλαμβάνει ημερομηνία και ώρα. 
                αριθμός cookie? 
                μέρη του ιστότοπου που προβάλατε ή αναζητήσατε· 
                και τον αριθμό τηλεφώνου που χρησιμοποιήσατε για να καλέσετε την Εξυπηρέτηση Πελατών μας. 
                Ενδέχεται επίσης να χρησιμοποιήσουμε δεδομένα προγράμματος περιήγησης, όπως cookies, 
                Flash cookies (γνωστά και ως Flash Local Shared Objects) ή παρόμοια δεδομένα σε ορισμένα μέρη του ιστότοπού μας για την πρόληψη απάτης και άλλους σκοπούς. 
                Κατά τη διάρκεια των επισκέψεών σας, ενδέχεται να χρησιμοποιήσουμε εργαλεία λογισμικού όπως το JavaScript για τη μέτρηση και τη συλλογή πληροφοριών περιόδων σύνδεσης, συμπεριλαμβανομένων των χρόνων απόκρισης σελίδας, σφαλμάτων λήψης, διάρκειας επισκέψεων σε ορισμένες σελίδες, πληροφοριών αλληλεπίδρασης σελίδας (όπως κύλιση, κλικ και μετατόπιση του ποντικιού) και μεθόδους που χρησιμοποιούνται για την απομάκρυνση από τη σελίδα. 
                Ενδέχεται επίσης να συλλέξουμε τεχνικές πληροφορίες για να μας βοηθήσουν να αναγνωρίσουμε τη συσκευή σας για λόγους πρόληψης απάτης και διαγνωστικούς σκοπούς. 
                διάρκεια επισκέψεων σε συγκεκριμένες σελίδες, πληροφορίες αλληλεπίδρασης σελίδων (όπως κύλιση, κλικ και μετατόπιση του ποντικιού) και μεθόδους που χρησιμοποιούνται για την απομάκρυνση από τη σελίδα. 
                Ενδέχεται επίσης να συλλέξουμε τεχνικές πληροφορίες για να μας βοηθήσουν να αναγνωρίσουμε τη συσκευή σας για λόγους πρόληψης απάτης και διαγνωστικούς σκοπούς. 
                διάρκεια επισκέψεων σε συγκεκριμένες σελίδες, πληροφορίες αλληλεπίδρασης σελίδων (όπως κύλιση, κλικ και μετατόπιση του ποντικιού) και μεθόδους που χρησιμοποιούνται για την απομάκρυνση από τη σελίδα. 
                Ενδέχεται επίσης να συλλέξουμε τεχνικές πληροφορίες για να μας βοηθήσουν να αναγνωρίσουμε τη συσκευή σας για λόγους πρόληψης απάτης και διαγνωστικούς σκοπούς.
                
            </p>
            <p>
                
                Συλλέγουμε αυτόματα ορισμένες πληροφορίες όταν επισκέπτεστε, χρησιμοποιείτε ή πλοηγείστε στην πλατφόρμα. 
                Αυτές οι πληροφορίες δεν αποκαλύπτουν τη συγκεκριμένη ταυτότητά σας (όπως το όνομά σας ή τα στοιχεία επικοινωνίας σας), αλλά μπορεί να περιλαμβάνουν πληροφορίες συσκευής και χρήσης, όπως τη διεύθυνση IP, το πρόγραμμα περιήγησης και τα χαρακτηριστικά της συσκευής, το λειτουργικό σύστημα, τις προτιμήσεις γλώσσας, τις διευθύνσεις URL αναφοράς, το όνομα της συσκευής, τη χώρα, την τοποθεσία , πληροφορίες σχετικά με το ποιος και πότε χρησιμοποιείτε μας και άλλες τεχνικές πληροφορίες. 
                Αυτές οι πληροφορίες απαιτούνται πρωτίστως για τη διατήρηση της ασφάλειας και της λειτουργίας της πλατφόρμας μας, καθώς και για τους σκοπούς της εσωτερικής μας ανάλυσης και αναφοράς.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Προσωπικό
                
                </strong>
            </p>
            <p>
                
                Εάν είστε εργαζόμενος ή αιτών στο The MorningView, συλλέγουμε πληροφορίες που μας παρέχετε οικειοθελώς. 
                Χρησιμοποιούμε τις πληροφορίες που συλλέγουμε για σκοπούς Ανθρώπινου Δυναμικού προκειμένου να διαχειριζόμαστε οφέλη σε εργαζόμενους και σε υποψηφίους ελέγχου.
                
            </p>
            <p>
                
                Μπορείτε να επικοινωνήσετε μαζί μας προκειμένου (1) να ενημερώσουμε ή να διορθώσουμε τα στοιχεία σας, (2) να αλλάξετε τις προτιμήσεις σας σε σχέση με τις επικοινωνίες και άλλες πληροφορίες που λαμβάνετε από εμάς ή (3) να λάβετε ένα αρχείο των πληροφοριών που έχουμε σχετικά με εσάς. 
                Τέτοιες ενημερώσεις, διορθώσεις, αλλαγές και διαγραφές δεν θα έχουν καμία επίδραση σε άλλες πληροφορίες που διατηρούμε ή πληροφορίες που έχουμε παράσχει σε τρίτους σύμφωνα με την παρούσα Πολιτική Απορρήτου πριν από την εν λόγω ενημέρωση, διόρθωση, αλλαγή ή διαγραφή.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Πώληση Επιχείρησης
                
                </strong>
            </p>
            <p>
                
                Διατηρούμε το δικαίωμα να μεταφέρουμε πληροφορίες σε τρίτο μέρος σε περίπτωση πώλησης, συγχώνευσης ή άλλης μεταβίβασης όλων ή ουσιαστικά όλων των περιουσιακών στοιχείων της The MorningView ή οποιασδήποτε από τις Εταιρικές Συνεργάτες της (όπως ορίζονται στο παρόν) ή αυτού του τμήματος της MorningView ή οποιεσδήποτε από τις Εταιρικές Θυγατρικές της με τις οποίες σχετίζεται η Υπηρεσία, ή σε περίπτωση που διακόψουμε την επιχείρησή μας ή υποβάλουμε αίτηση ή έχουμε υποβάλει αίτηση εναντίον μας σε πτώχευση, αναδιοργάνωση ή παρόμοια διαδικασία, υπό την προϋπόθεση ότι το τρίτο μέρος συμφωνεί να τηρήσει τους όρους αυτής της Πολιτικής Απορρήτου.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Συνεργάτες
                
                </strong>
            </p>
            <p>
                
                Ενδέχεται να αποκαλύψουμε πληροφορίες (συμπεριλαμβανομένων προσωπικών πληροφοριών) για εσάς στις Εταιρικές Συνεργάτες μας. 
                Για τους σκοπούς της παρούσας Πολιτικής Απορρήτου, "Εταιρική Συνεργάτης" σημαίνει οποιοδήποτε πρόσωπο ή οντότητα που ελέγχει άμεσα ή έμμεσα, ελέγχεται ή βρίσκεται υπό κοινό έλεγχο με το The MorningView, είτε από ιδιοκτησία είτε με άλλο τρόπο. 
                Οποιεσδήποτε πληροφορίες που σχετίζονται με εσάς που παρέχουμε στις Εταιρικές Συνεργάτες μας θα αντιμετωπίζονται από αυτές τις Εταιρικές Συνεργάτες σύμφωνα με τους όρους της παρούσας Πολιτικής Απορρήτου.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Κυβερνητικός νόμος
                
                </strong>
            </p>
            <p>
                
                Αυτή η Πολιτική Απορρήτου διέπεται από τους νόμους της Ελλάδας χωρίς να λαμβάνεται υπόψη η διάταξη περί σύγκρουσης νόμων. 
                Συναινείτε στην αποκλειστική δικαιοδοσία των δικαστηρίων σε σχέση με οποιαδήποτε ενέργεια ή διαφορά που προκύπτει μεταξύ των μερών βάσει ή σε σχέση με αυτήν την Πολιτική Απορρήτου, εκτός από εκείνα τα άτομα που μπορεί να έχουν δικαιώματα να υποβάλουν αξιώσεις βάσει της Ασπίδας Απορρήτου ή του πλαισίου Ελβετίας-ΗΠΑ.
                
            </p>
            <p>
                
                Οι νόμοι της Ελλάδας, εξαιρουμένων των κανόνων περί σύγκρουσης νόμων, θα διέπουν την παρούσα Συμφωνία και τη χρήση της εφαρμογής από εσάς. 
                Η χρήση της εφαρμογής από εσάς ενδέχεται επίσης να υπόκειται σε άλλους τοπικούς, πολιτειακούς, εθνικούς ή διεθνείς νόμους.
                
            </p>
            <p>
                
                Χρησιμοποιώντας το MorningView ή επικοινωνώντας απευθείας μαζί μας, δηλώνετε ότι αποδέχεστε αυτήν την Πολιτική Απορρήτου. 
                Εάν δεν συμφωνείτε με αυτήν την Πολιτική Απορρήτου, δεν θα πρέπει να ασχοληθείτε με τον ιστότοπό μας ή να χρησιμοποιήσετε τις υπηρεσίες μας. 
                Η συνέχιση της χρήσης του ιστότοπου, η άμεση συνεργασία μαζί μας ή η δημοσίευση αλλαγών στην παρούσα Πολιτική Απορρήτου που δεν επηρεάζουν σημαντικά τη χρήση ή την αποκάλυψη των προσωπικών σας στοιχείων θα σημαίνει ότι αποδέχεστε αυτές τις αλλαγές.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Η συγκατάθεσή σας
                
                </strong>
            </p>
            <p>
                
                Ενημερώσαμε την Πολιτική Απορρήτου μας για να σας παρέχουμε πλήρη διαφάνεια σχετικά με το τι ορίζεται όταν επισκέπτεστε τον ιστότοπό μας και πώς χρησιμοποιείται. 
                Με τη χρήση της εφαρμογής μας, την εγγραφή λογαριασμού ή την πραγματοποίηση μιας αγοράς, συναινείτε με την Πολιτική Απορρήτου μας και συμφωνείτε με τους όρους της.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Σύνδεσμοι προς άλλους ιστότοπους
                
                </strong>
            </p>
            <p>
                
                Αυτή η Πολιτική Απορρήτου ισχύει μόνο για τις Υπηρεσίες. 
                Οι Υπηρεσίες ενδέχεται να περιέχουν συνδέσμους προς άλλους ιστότοπους που δεν λειτουργούν ή ελέγχονται από το The MorningView. 
                Δεν φέρουμε ευθύνη για το περιεχόμενο, την ακρίβεια ή τις απόψεις που εκφράζονται σε τέτοιους ιστότοπους και τέτοιοι ιστότοποι δεν διερευνώνται, παρακολουθούνται ή ελέγχονται ως προς την ακρίβεια ή την πληρότητα από εμάς. 
                Να θυμάστε ότι όταν χρησιμοποιείτε έναν σύνδεσμο για να μεταβείτε από τις Υπηρεσίες σε άλλο ιστότοπο, η Πολιτική Απορρήτου μας δεν ισχύει πλέον. 
                Η περιήγησή σας και η αλληλεπίδρασή σας σε οποιονδήποτε άλλο ιστότοπο, συμπεριλαμβανομένων αυτών που διαθέτουν σύνδεσμο στην πλατφόρμα μας, υπόκεινται στους κανόνες και τις πολιτικές αυτού του ιστότοπου. 
                Αυτά τα τρίτα μέρη ενδέχεται να χρησιμοποιήσουν τα δικά τους cookies ή άλλες μεθόδους για τη συλλογή πληροφοριών σχετικά με εσάς.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Διαφήμιση
                
                </strong>
            </p>
            <p>
                
                Αυτή η εφαρμογή μπορεί να περιέχει διαφημίσεις τρίτων και συνδέσμους προς ιστότοπους τρίτων. 
                Το MorningView δεν προβαίνει σε καμία δήλωση ως προς την ακρίβεια ή την καταλληλότητα οποιασδήποτε από τις πληροφορίες που περιέχονται σε αυτές τις διαφημίσεις ή ιστότοπους και δεν αποδέχεται καμία ευθύνη ή ευθύνη για τη συμπεριφορά ή το περιεχόμενο αυτών των διαφημίσεων και τοποθεσιών και τις προσφορές που γίνονται από τρίτα μέρη .
                
            </p>
            <p>
                
                Η διαφήμιση διατηρεί το The MorningView και πολλούς από τους ιστότοπους και τις υπηρεσίες που χρησιμοποιείτε δωρεάν. 
                Εργαζόμαστε σκληρά για να διασφαλίσουμε ότι οι διαφημίσεις είναι ασφαλείς, διακριτικές και όσο το δυνατόν πιο σχετικές.
                
            </p>
            <p>
                
                Οι διαφημίσεις τρίτων και οι σύνδεσμοι προς άλλους ιστότοπους όπου διαφημίζονται αγαθά ή υπηρεσίες δεν αποτελούν εγκρίσεις ή συστάσεις από το The MorningView των τοποθεσιών, αγαθών ή υπηρεσιών τρίτων. 
                Το MorningView δεν φέρει καμία ευθύνη για το περιεχόμενο οποιασδήποτε από τις διαφημίσεις, τις υποσχέσεις που έχουν δοθεί ή την ποιότητα/αξιοπιστία των προϊόντων ή των υπηρεσιών που προσφέρονται σε όλες τις διαφημίσεις.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Cookies για διαφήμιση
                
                </strong>
            </p>
            <p>
                
                Αυτά τα cookies συλλέγουν πληροφορίες με την πάροδο του χρόνου σχετικά με τη δραστηριότητά σας στο διαδίκτυο στην εφαρμογή και σε άλλες διαδικτυακές υπηρεσίες για να κάνουν τις διαδικτυακές διαφημίσεις πιο σχετικές και αποτελεσματικές για εσάς. 
                Αυτό είναι γνωστό ως διαφήμιση βάσει ενδιαφέροντος. 
                Εκτελούν επίσης λειτουργίες όπως η αποτροπή της συνεχούς επανεμφάνισης της ίδιας διαφήμισης και η διασφάλιση της σωστής εμφάνισης των διαφημίσεων για τους διαφημιστές. 
                Χωρίς cookie, είναι πραγματικά δύσκολο για έναν διαφημιζόμενο να προσεγγίσει το κοινό του ή να γνωρίζει πόσες διαφημίσεις εμφανίστηκαν και πόσα κλικ έλαβαν.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Μπισκότα
                
                </strong>
            </p>
            <p>
                
                Το MorningView χρησιμοποιεί "Cookies" για να προσδιορίσει τις περιοχές του ιστότοπού μας που έχετε επισκεφτεί. 
                Ένα Cookie είναι ένα μικρό κομμάτι δεδομένων που αποθηκεύεται στον υπολογιστή ή στην κινητή συσκευή σας από το πρόγραμμα περιήγησής σας. 
                Χρησιμοποιούμε Cookies για να βελτιώσουμε την απόδοση και τη λειτουργικότητα της εφαρμογής μας, αλλά δεν είναι απαραίτητα για τη χρήση τους. 
                Ωστόσο, χωρίς αυτά τα cookie, ορισμένες λειτουργίες όπως τα βίντεο ενδέχεται να μην είναι διαθέσιμες ή θα σας ζητηθεί να εισάγετε τα στοιχεία σύνδεσής σας κάθε φορά που επισκέπτεστε την εφαρμογή, καθώς δεν θα μπορούσαμε να θυμηθούμε ότι είχατε συνδεθεί στο παρελθόν. 
                Τα περισσότερα προγράμματα περιήγησης ιστού μπορούν να ρυθμιστούν ώστε να απενεργοποιούν τη χρήση των Cookies. 
                Ωστόσο, εάν απενεργοποιήσετε τα Cookies, ενδέχεται να μην μπορείτε να έχετε πρόσβαση στη λειτουργικότητα του ιστότοπού μας σωστά ή καθόλου. 
                Ποτέ δεν τοποθετούμε Προσωπικές Αναγνωριστικές Πληροφορίες στα Cookies.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Αποκλεισμός και απενεργοποίηση cookie και παρόμοιων τεχνολογιών
                
                </strong>
            </p>
            <p>
                
                Οπουδήποτε κι αν βρίσκεστε, μπορείτε επίσης να ρυθμίσετε το πρόγραμμα περιήγησής σας να αποκλείει τα cookies και παρόμοιες τεχνολογίες, αλλά αυτή η ενέργεια μπορεί να αποκλείσει τα βασικά μας cookie και να εμποδίσει τη σωστή λειτουργία του ιστότοπού μας και ενδέχεται να μην μπορείτε να χρησιμοποιήσετε πλήρως όλες τις δυνατότητες και τις υπηρεσίες του. 
                Θα πρέπει επίσης να γνωρίζετε ότι μπορεί επίσης να χάσετε κάποιες αποθηκευμένες πληροφορίες (π.χ. αποθηκευμένα στοιχεία σύνδεσης, προτιμήσεις ιστότοπου) εάν αποκλείσετε τα cookies στο πρόγραμμα περιήγησής σας. 
                Διαφορετικά προγράμματα περιήγησης διαθέτουν διαφορετικά στοιχεία ελέγχου. 
                Η απενεργοποίηση ενός cookie ή μιας κατηγορίας cookie δεν διαγράφει το cookie από το πρόγραμμα περιήγησής σας, θα πρέπει να το κάνετε μόνοι σας μέσα από το πρόγραμμα περιήγησής σας, θα πρέπει να επισκεφτείτε το μενού βοήθειας του προγράμματος περιήγησής σας για περισσότερες πληροφορίες.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Οι λεπτομέρειες πληρωμής
                
                </strong>
            </p>
            <p>
                
                Αναφορικά με οποιαδήποτε πιστωτική κάρτα ή άλλα στοιχεία διεκπεραίωσης πληρωμών που μας έχετε δώσει, δεσμευόμαστε ότι αυτές οι εμπιστευτικές πληροφορίες θα αποθηκευτούν με τον ασφαλέστερο δυνατό τρόπο.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Παιδικό απόρρητο
                
                </strong>
            </p>
            <p>
                
                Δεν απευθυνόμαστε σε κανέναν κάτω των 13 ετών. Δεν συλλέγουμε εν γνώσει μας στοιχεία προσωπικής ταυτοποίησης από κανέναν κάτω των 13 ετών. Εάν είστε γονέας ή κηδεμόνας και γνωρίζετε ότι το παιδί σας μας έχει παράσχει Προσωπικά Δεδομένα, επικοινωνήστε Μας. 
                Εάν αντιληφθούμε ότι έχουμε συλλέξει Προσωπικά Δεδομένα από οποιονδήποτε κάτω των 13 ετών χωρίς επαλήθευση της γονικής συναίνεσης, λαμβάνουμε μέτρα για να αφαιρέσουμε αυτές τις πληροφορίες από τους διακομιστές μας.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Αλλαγές στην Πολιτική Απορρήτου μας
                
                </strong>
            </p>
            <p>
                
                Ενδέχεται να αλλάξουμε την Υπηρεσία και τις πολιτικές μας και μπορεί να χρειαστεί να κάνουμε αλλαγές στην παρούσα Πολιτική Απορρήτου, ώστε να αντικατοπτρίζουν με ακρίβεια την Υπηρεσία και τις πολιτικές μας. 
                Εκτός εάν απαιτείται διαφορετικά από τη νομοθεσία, θα σας ειδοποιήσουμε (για παράδειγμα, μέσω της Υπηρεσίας μας) προτού πραγματοποιήσουμε αλλαγές στην παρούσα Πολιτική Απορρήτου και θα σας δώσουμε την ευκαιρία να τις ελέγξετε προτού τεθούν σε ισχύ. 
                Στη συνέχεια, εάν συνεχίσετε να χρησιμοποιείτε την Υπηρεσία, θα δεσμεύεστε από την ενημερωμένη Πολιτική Απορρήτου. 
                Εάν δεν θέλετε να συμφωνήσετε με αυτήν ή οποιαδήποτε ενημερωμένη Πολιτική Απορρήτου, μπορείτε να διαγράψετε τον λογαριασμό σας.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Υπηρεσίες τρίτων
                
                </strong>
            </p>
            <p>
                
                Ενδέχεται να προβάλλουμε, να συμπεριλάβουμε ή να διαθέσουμε περιεχόμενο τρίτων (συμπεριλαμβανομένων δεδομένων, πληροφοριών, εφαρμογών και υπηρεσιών άλλων προϊόντων) ή να παρέχουμε συνδέσμους σε ιστότοπους ή υπηρεσίες τρίτων ("Υπηρεσίες τρίτων").
                
            </p>
            <p>
                
                Αναγνωρίζετε και συμφωνείτε ότι το The MorningView δεν θα είναι υπεύθυνο για οποιεσδήποτε Υπηρεσίες Τρίτων, συμπεριλαμβανομένης της ακρίβειας, της πληρότητας, της επικαιρότητας, της εγκυρότητας, της συμμόρφωσης με τα πνευματικά δικαιώματα, της νομιμότητας, της ευπρέπειας, της ποιότητας ή οποιασδήποτε άλλης πτυχής τους. 
                Το MorningView δεν αναλαμβάνει και δεν φέρει καμία ευθύνη ή ευθύνη έναντι εσάς ή οποιουδήποτε άλλου προσώπου ή οντότητας για οποιεσδήποτε Υπηρεσίες Τρίτων.
                
            </p>
            <p>
                
                Οι Υπηρεσίες Τρίτων και οι σύνδεσμοι προς αυτές παρέχονται αποκλειστικά για διευκόλυνση σε εσάς και έχετε πρόσβαση και τις χρησιμοποιείτε εξ ολοκλήρου με δική σας ευθύνη και με την επιφύλαξη των όρων και προϋποθέσεων αυτών των τρίτων.
                
            </p>
            <ul></ul>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Πληροφορίες σχετικά με τον Γενικό Κανονισμό Προστασίας Δεδομένων (GDPR)
                
                </strong>
            </p>
            <p>
                
                Ενδέχεται να συλλέγουμε και να χρησιμοποιούμε πληροφορίες από εσάς εάν προέρχεστε από τον Ευρωπαϊκό Οικονομικό Χώρο (ΕΟΧ) και σε αυτήν την ενότητα της Πολιτικής Απορρήτου μας θα εξηγήσουμε ακριβώς πώς και γιατί συλλέγονται αυτά τα δεδομένα και πώς διατηρούμε αυτά τα δεδομένα προστασία από αντιγραφή ή χρήση με λάθος τρόπο.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Τι είναι το GDPR;
                
                </strong>
            </p>
            <p>
                
                Ο GDPR είναι ένας πανευρωπαϊκός νόμος περί απορρήτου και προστασίας δεδομένων που ρυθμίζει τον τρόπο με τον οποίο προστατεύονται τα δεδομένα των κατοίκων της ΕΕ από εταιρείες και ενισχύει τον έλεγχο που έχουν οι κάτοικοι της ΕΕ στα προσωπικά τους δεδομένα.
                
            </p>
            <p>
                
                Ο GDPR είναι σχετικός με οποιαδήποτε εταιρεία που λειτουργεί παγκοσμίως και όχι μόνο τις επιχειρήσεις και τους κατοίκους της ΕΕ που εδρεύουν στην ΕΕ. 
                Τα δεδομένα των πελατών μας είναι σημαντικά ανεξάρτητα από το πού βρίσκονται, γι' αυτό έχουμε εφαρμόσει τους ελέγχους GDPR ως βασικό μας πρότυπο για όλες τις δραστηριότητές μας παγκοσμίως.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Τι είναι τα προσωπικά δεδομένα;
                
                </strong>
            </p>
            <p>
                
                Οποιαδήποτε δεδομένα σχετίζονται με ταυτοποιήσιμο ή ταυτοποιημένο άτομο. 
                Ο GDPR καλύπτει ένα ευρύ φάσμα πληροφοριών που θα μπορούσαν να χρησιμοποιηθούν από μόνες τους ή σε συνδυασμό με άλλες πληροφορίες για την ταυτοποίηση ενός ατόμου. 
                Τα προσωπικά δεδομένα εκτείνονται πέρα ​​από το όνομα ή τη διεύθυνση email ενός ατόμου. 
                Μερικά παραδείγματα περιλαμβάνουν οικονομικές πληροφορίες, πολιτικές απόψεις, γενετικά δεδομένα, βιομετρικά δεδομένα, διευθύνσεις IP, φυσική διεύθυνση, σεξουαλικό προσανατολισμό και εθνικότητα.
                
            </p>
            <p>
                
                Οι Αρχές Προστασίας Δεδομένων περιλαμβάνουν απαιτήσεις όπως:
                
            </p>
            <ul>
                <li>
                <p>
                    
                    Τα προσωπικά δεδομένα που συλλέγονται πρέπει να υποβάλλονται σε επεξεργασία με δίκαιο, νόμιμο και διαφανή τρόπο και θα πρέπει να χρησιμοποιούνται μόνο με τρόπο που εύλογα θα περίμενε ένα άτομο.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Τα προσωπικά δεδομένα θα πρέπει να συλλέγονται μόνο για την εκπλήρωση συγκεκριμένου σκοπού και θα πρέπει να χρησιμοποιούνται μόνο για αυτόν τον σκοπό. 
                    Οι οργανισμοί πρέπει να προσδιορίζουν γιατί χρειάζονται τα προσωπικά δεδομένα όταν τα συλλέγουν.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Τα προσωπικά δεδομένα δεν θα πρέπει να διατηρούνται περισσότερο από όσο είναι απαραίτητο για την εκπλήρωση του σκοπού τους.
                    
                </p>
                </li>
                <li>
                <p>
                    
                    Τα άτομα που καλύπτονται από τον GDPR έχουν δικαίωμα πρόσβασης στα προσωπικά τους δεδομένα. 
                    Μπορούν επίσης να ζητήσουν αντίγραφο των δεδομένων τους και τα δεδομένα τους να ενημερωθούν, να διαγραφούν, να περιοριστούν ή να μετακινηθούν σε άλλο οργανισμό.
                    
                </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Γιατί είναι σημαντικός ο GDPR;
                
                </strong>
            </p>
            <p>
                
                Ο GDPR προσθέτει ορισμένες νέες απαιτήσεις σχετικά με τον τρόπο με τον οποίο οι εταιρείες πρέπει να προστατεύουν τα προσωπικά δεδομένα των ατόμων που συλλέγουν και επεξεργάζονται. 
                Αυξάνει επίσης τα διακυβεύματα για συμμόρφωση αυξάνοντας την επιβολή και επιβάλλοντας μεγαλύτερα πρόστιμα για παραβίαση. 
                Πέρα από αυτά τα γεγονότα, είναι απλώς το σωστό. 
                Στο The MorningView πιστεύουμε ακράδαντα ότι το απόρρητο των δεδομένων σας είναι πολύ σημαντικό και έχουμε ήδη εφαρμόσει σταθερές πρακτικές ασφάλειας και απορρήτου που υπερβαίνουν τις απαιτήσεις αυτού του νέου κανονισμού.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Δικαιώματα Ατομικού Υποκειμένου Δεδομένων - Πρόσβαση, Φορητότητα και Διαγραφή δεδομένων
                
                </strong>
            </p>
            <p>
                
                Δεσμευόμαστε να βοηθήσουμε τους πελάτες μας να ανταποκριθούν στις απαιτήσεις του GDPR για τα δικαιώματα των υποκειμένων των δεδομένων. 
                Το MorningView επεξεργάζεται ή αποθηκεύει όλα τα προσωπικά δεδομένα σε πλήρως ελεγμένους, συμβατούς με την DPA προμηθευτές. 
                Αποθηκεύουμε όλες τις συνομιλίες και τα προσωπικά δεδομένα για έως και 6 χρόνια, εκτός εάν διαγραφεί ο λογαριασμός σας. 
                Σε αυτήν την περίπτωση, διαθέτουμε όλα τα δεδομένα σύμφωνα με τους Όρους Παροχής Υπηρεσιών και την Πολιτική Απορρήτου μας, αλλά δεν θα τα διατηρήσουμε για περισσότερο από 60 ημέρες.
                
            </p>
            <p>
                
                Γνωρίζουμε ότι εάν εργάζεστε με πελάτες της ΕΕ, πρέπει να μπορείτε να τους παρέχετε τη δυνατότητα πρόσβασης, ενημέρωσης, ανάκτησης και κατάργησης προσωπικών δεδομένων. 
                Σε καταλάβαμε! 
                Έχουμε δημιουργηθεί ως αυτοεξυπηρέτηση από την αρχή και σας δίναμε πάντα πρόσβαση στα δεδομένα σας και στα δεδομένα των πελατών σας. 
                Η ομάδα υποστήριξης πελατών μας είναι εδώ για να απαντήσει σε τυχόν ερωτήσεις που μπορεί να έχετε σχετικά με την εργασία με το API.
                
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                
                    Επικοινωνήστε μαζί μας
                
                </strong>
            </p>
            <p>
                
                Μη διστάσετε να επικοινωνήσετε μαζί μας εάν έχετε οποιεσδήποτε ερωτήσεις.
                
            </p>
            <ul>
                <li>
                <p>
                    
                    Μέσω email: info@morningview.gr 
                    
                    <br/>
                    <br/>
                    
                    Πολιτική απορρήτου της εφαρμογής MorningView
                    
                </p>
                </li>
            </ul>
            </div>
        </div>
    );};
export default PrivacyContainerGreek;