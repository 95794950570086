import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { BaseUrl } from "../App";
import AuthorsArticleContainer from "../components/pageComponents/AuthorsArticle/AuthorsArticleContainer";
import LoadingPage from "./LoadingPage";
// import classes from "../styles/AuthorsArticle.module.css"


const AuthorsArticle = () => {
    const search = useLocation();
    const { slug } = useParams();
    var articleId = search?.state?.id || null;
    const [isLoading,setLoading] = useState(true);
    const [article,setArticle] = useState([]);
    const [tags,setTags] = useState([]);
    useEffect(()=>{
        axios.get(BaseUrl+'/get-single-article',{
            params: {
                id: articleId,
                slug: slug
            }
        }).then((response) =>{
            setArticle(response.data.data);
            articleId = response.data.data.id;
            setLoading(false);
            window.scrollTo(0, 0);
        });
        axios.get(BaseUrl+"/get-tags").then((response)=> {
            setTags(response.data.data);
        })
    },[articleId, slug])
    if(isLoading === false){
        return (
            <div>
                <AuthorsArticleContainer key={articleId} material = {article} tags={tags} openedId={articleId}/>
            </div>)
    }else{
        return <LoadingPage />;
    }
   
}

export default AuthorsArticle;

