import React from "react";
import {useState, useEffect} from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { BaseUrl } from "../App";
import ArticleContainer from "../components/pageComponents/Article/ArticleContainer";
import LoadingPage from "./LoadingPage";
import { useParams } from 'react-router-dom';
import { type } from "os";

const Article = () => {
    const { slug } = useParams();
    const search = useLocation();
    const articleId = search.state;
    const [isLoading,setLoading] = useState(true);
    const [theArticle,setArticle] = useState([]);
    const [tags,setTags] = useState([]);
    useEffect(() => {
        axios.get(BaseUrl+'/get-single-article',{
            params: {
                id: articleId?.id,
                slug: slug
            }
        }).then((response) => {
            setLoading(false);
            setArticle(response.data.data);
            
        });
        axios.get(BaseUrl+'/get-tags').then((response)=>{
            setTags(response.data.data)
        });
    }, [articleId])
    if(isLoading == false){
        return(
            <div className="slideInAnimation">
                <ArticleContainer material={theArticle} tags={tags}/>
            </div>)
    }else{
        return(<div><LoadingPage /></div>)
    }
    
    
   
}

export default Article