import { Link } from "react-router-dom";
import {createRef, useRef, useState} from "react";
import axios from "axios";
import styles from "../styles/About.module.css";
import android from "../images/androidImg.png";
import ios from "../images/iosImg.png";
import facebook from "../images/fb.png";
import twitter from "../images/twit.png";
import linkedin from "../images/linked.png";
import instagram from "../images/insta.png";
import { Helmet } from "react-helmet-async";
import { BaseUrl } from "../App";
import { send } from "process";

const About = () => {
    const [messageSent,setMessageSent] = useState(false);
    const [error,setError] = useState('');
    const message = useRef<HTMLTextAreaElement>(null);
    const name = useRef<HTMLInputElement>(null);
    const surname = useRef<HTMLInputElement>(null);
    const email = useRef<HTMLInputElement>(null);
    const sendMessage = () => {
        error != '' && setError('');
        const nameText = name?.current?.value ?? '';
        const surnameText = surname?.current?.value ?? '';
        const emailText = email?.current?.value ?? '';
        const messageText = message?.current?.value ?? '';
        const data = {
            name: nameText,
            surname: surnameText,
            email: emailText,
            message: messageText
        };
        axios.post(BaseUrl+'/contact-form', data).then((response: any) => {
            if (response.data.success) {
                setMessageSent(true);
                setTimeout(() =>{
                    setMessageSent(false);
                }, 7000);
            } else {
                messageSent && setMessageSent(false);
                let errMessage = '';
                for (const err in response.data.data) {
                    errMessage += (response.data.data[err][0] + "<br/>");
                }
                setError('Error: <br/>' + errMessage);
            }
        }).catch((error: any)=> {
            messageSent && setMessageSent(false);
            let errMessage = '';
            for (const err in error.response.data.data) {
                errMessage += (error.response.data.data[err][0] + "<br/>");
            }
            setError('Error: <br/>' + errMessage);
        });
    };
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>{'The Morning View | About'}</title>
            </Helmet>
            <div className={styles.mainBanner}>
                <h1>Σχετικά με εμάς</h1>
            </div>
            <div className={styles.mainContainerInner}>
                <h2>Τhe MorningView</h2>
                <p>Το MorningView είναι η πρώτη, εξειδικευμένη εφαρμογή ενημέρωσης στην ελληνική αγορά.</p><br/>
                <p>Κάθε πρωί, η συντακτική ομάδα του καταγράφει τον παλμό της οικονομίας, των επιχειρήσεων, των αγορών και της πολιτικής παρουσιάζοντας αποκλειστικές ειδήσεις και πληροφορίες, πλούσιο παρασκήνιο, αναλύσεις, συνεντεύξεις και άρθρα ειδικών.</p><br/>
                <p>Το MorningView μέσα από το site <a href="www.morningview.gr">www.morningview.gr</a>, αλλά και μέσα από τις εξειδικευμένες Android και iOS εφαρμογές  προσφέρει στους αναγνώστες του έγκαιρη και έγκυρη ενημέρωση κάθε πρωί στις 7:00, έκτακτες ενημερώσεις(breaking news) και alerts κατά τη διάρκεια της ημέρας και πρόσβαση σε επιπλέον, premium περιεχόμενο, το οποίο θα εμπλουτίζεται διαρκώς.</p><br/>
                <p style={{fontWeight:'bold'}}>Kατεβαστε το MorningView app εδώ:</p>
            </div>
            <div className={styles.bnrDownload}>
                <div>
                    {/* <p className={styles.brnText}>Android: <a href="http://t.ly/k8FR">http://t.ly/k8FR</a></p> */}
                    <p className={styles.brnText}><a href="https://play.google.com/store/apps/details?id=com.viewmedia.themorningview&referrer=utm_source%3Dwebsite%26utm_medium%3Dbanner%26utm_term%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26utm_content%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26utm_campaign%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26anid%3Dadmob" target="_blank">Android Google Play Store</a></p>
                    <a href="https://play.google.com/store/apps/details?id=com.viewmedia.themorningview&referrer=utm_source%3Dwebsite%26utm_medium%3Dbanner%26utm_term%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26utm_content%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26utm_campaign%3DNews%2520Application%2520Android%2520Google%2520Play%2520Store%26anid%3Dadmob" target="_blank"><img className={styles.downloadImg} src={android} alt=''/></a>
                </div>
                <div>
                    {/* <p className={styles.brnText}>iOS: <a href="http://t.ly/iFMo">http://t.ly/iFMo</a></p> */}
                    <p className={styles.brnText}><a href="https://apps.apple.com/gr/app/the-morning-view-new/id6470270796?utm_source=website&utm_medium=banner&utm_campaign=News+Application+iOS+App+Store&utm_term=News+mobile+application+iOS+App+Store&utm_content=News+mobile+application+iOS+App+Store" target="_blank">iOS App Store</a></p>
                    <a href="https://apps.apple.com/gr/app/the-morning-view-new/id6470270796?utm_source=website&utm_medium=banner&utm_campaign=News+Application+iOS+App+Store&utm_term=News+mobile+application+iOS+App+Store&utm_content=News+mobile+application+iOS+App+Store" target="_blank"><img className={styles.downloadImg} src={ios} alt=''/></a>
                </div>
            </div>
            <div className={styles.mainContainerInner}>
                <p>MorningView, τώρα η έγκυρη, έγκαιρη και υπεύθυνη ενημέρωση είναι στο χέρι σας!</p><br/>
                <div>
                    <br/><h2>Επικοινωνία</h2>
                    <div>
                        <img className={styles.socialImg} src={facebook} alt=''/>
                        <img className={styles.socialImg} src={twitter} alt=''/>
                        <img className={styles.socialImg} src={linkedin} alt=''/>
                        <img className={styles.socialImg} src={instagram} alt=''/>
                    </div>
                    <div className={styles.form}>
                        <div className={styles.formLeftInner}>
                            <input type="text" placeholder="First Name..." ref={name}/>
                            <input type="text" placeholder="Last Name..." ref={surname}/>
                            <input type="text" placeholder="Email..." ref={email}/>
                        </div>
                        <div className={styles.formRightInner}>
                            <textarea placeholder="Message..." ref={message}></textarea>
                            <button type="submit" onClick={() => sendMessage()} disabled={messageSent}>Send</button>
                        </div>
                        <div className={styles.formRightInner}><p dangerouslySetInnerHTML={{__html: error}}></p></div>
                        <div className={styles.formRightInner}><p> {messageSent ? 'To μήνυμα εστάλη επιτυχώς' : ''}</p></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;