import React from "react";
import topbarcss from "../../styles/TopBar.module.css";
import NavStyle from "../../styles/TopBar.module.css";
import morningviewLogo from '../../images/themorningview-logo.jpg';
import home from "../../images/home.png";
import news from "../../images/news.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import MyDate from "./Date";
import settings from "../../images/settings.png";
import loginImg from "../../images/login.png";
import styles from '../../styles/main.module.css';
import logo from '../../images/themorningview-logo.jpg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import { BaseUrl } from "../../App";

const TopBar = () => {
  const [Trigger, SetTrigger] = useState(NavStyle.ChangePassDiv);

  const [Hide, setHide] = useState(false);
  
  const OpenSubMenu = (e:any) => {
    if (Hide === false) {
      SetTrigger(NavStyle.ChangePassDivClicked);
      setHide(true);
    } else if (Hide === true) {
      SetTrigger(NavStyle.ChangePassDiv);
      setHide(false);
    }
  };


  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect( () => {
    if(sessionStorage.getItem("token") === null || sessionStorage.getItem("token") === undefined){
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [])

  const dologout = () =>{
    axios.post(BaseUrl + '/logout', {}, {
      headers: { Authorization:sessionStorage.getItem("token") }
    }).then((response) => {
      sessionStorage.removeItem("token"); 
      sessionStorage.removeItem("subscribed"); 
      window.location.reload();
    }).catch((error) =>{
      alert("Πρόβλημα")
    });
  }

  return (
    <header>
      <Container fluid className={styles.header}>
          <Row className="h-100  d-flex align-items-center text-center text-white">
              <Col>
                    <Link to="/">
                      <img src={logo} height="55px" className={styles.headerLogo}></img>
                    </Link>
              </Col>
              <Col className="d-lg-flex d-none">Premium Content on Business and Finance 
                  <span className={styles.headerDaily}> 
                      DAILY
                  </span>
              </Col>
              <Col className="d-lg-flex d-none"><MyDate /></Col>
          </Row>
          
      </Container>
    </header>
  );

  return (
    <div className={topbarcss.topbarcontainer}>
      <div className={NavStyle.NavContainerInner}>

        <div>
          <Link to="/">
            <img className={NavStyle.logo} src={morningviewLogo} alt=""></img>
          </Link>
        </div>

        <div>
          <span className={NavStyle.mainTitle}> Premium Content on Business and Finance <span style={{fontWeight:'bold'}}>DAILY</span></span>
        </div>

        <div>
          <span className={NavStyle.datetimeText}>
            <MyDate />
          </span>
        </div>

        {/* <div className={NavStyle.settingsContainer}>
          <div>

            {
              isLoggedIn == false ? 
                <Link to="/login" className={NavStyle.LoginDiv}>
                <img className={NavStyle.LoginImg} src={loginImg} alt=""></img>
                <p className={NavStyle.LoginText}>Log In | Sign Up</p>
                </Link> 
              : 
                <div className={NavStyle.LoginDiv}>
                  <p onClick={ () => { dologout() }} className={NavStyle.LogoutText}>Αποσύνδεση</p>
                </div>
            }

          </div>
          <div className={NavStyle.SettingsDiv} >
            <img src={settings} alt="" onClick={OpenSubMenu}></img>
          </div>
          <div className={Trigger}>
            
            <div><a href="/app-privacy-policy">App Privacy Policy</a></div>
            <div><a href="/eula">App terms EULA</a></div>
            {
              isLoggedIn == false ? 
                <div></div>
              :
              <div><a href="/change-password">Αλλαγή password</a></div>
            }
            
          </div>
        </div> */}
      </div>

      {/* <div className={topbarcss.topbarcontainerInner}>
        <div className={NavStyle.navbarContainer}>
          <button className={`${NavStyle.boxes}`}>
            <Link to="/">
              <div className={`${NavStyle.boxesInner}`}>
                <img src={home} alt=""></img>
                <div className={NavStyle.Textbox}>Αρχική</div>
              </div>
            </Link>
          </button>
          <button className={`${NavStyle.boxes}`}>
            <Link to="about">
              <div className={`${NavStyle.boxesInner}`}>
                <img src={news} alt=""></img>
                <div className={NavStyle.Textbox}>About</div>
              </div>
            </Link>
          </button>
          <button className={`${NavStyle.boxes}`}>
            <Link to="blog">
              <div className={`${NavStyle.boxesInner}`}>
                <img src={news} alt=""></img>
                <div className={NavStyle.Textbox}>Στήλες</div>
              </div>
            </Link>
          </button>
        </div>
      </div> */}
    </div>
  );








  // return (
  //   <div className={topbarcss.topbarcontainer}>
  //     <div className={NavStyle.NavContainerInner}>

  //       <div>
  //         <Link to="/">
  //           <img className={NavStyle.logo} src={morningviewLogo} alt=""></img>
  //         </Link>
  //       </div>

  //       <div>
  //         <span className={NavStyle.mainTitle}> Premium Content on Business and Finance <span style={{fontWeight:'bold'}}>DAILY</span></span>
  //       </div>

  //       <div>
  //         <span className={NavStyle.datetimeText}>
  //           <MyDate />
  //         </span>
  //       </div>

  //       {/* <div className={NavStyle.settingsContainer}>
  //         <div>

  //           {
  //             isLoggedIn == false ? 
  //               <Link to="/login" className={NavStyle.LoginDiv}>
  //               <img className={NavStyle.LoginImg} src={loginImg} alt=""></img>
  //               <p className={NavStyle.LoginText}>Log In | Sign Up</p>
  //               </Link> 
  //             : 
  //               <div className={NavStyle.LoginDiv}>
  //                 <p onClick={ () => { dologout() }} className={NavStyle.LogoutText}>Αποσύνδεση</p>
  //               </div>
  //           }

  //         </div>
  //         <div className={NavStyle.SettingsDiv} >
  //           <img src={settings} alt="" onClick={OpenSubMenu}></img>
  //         </div>
  //         <div className={Trigger}>
            
  //           <div><a href="/app-privacy-policy">App Privacy Policy</a></div>
  //           <div><a href="/eula">App terms EULA</a></div>
  //           {
  //             isLoggedIn == false ? 
  //               <div></div>
  //             :
  //             <div><a href="/change-password">Αλλαγή password</a></div>
  //           }
            
  //         </div>
  //       </div> */}
  //     </div>

  //     {/* <div className={topbarcss.topbarcontainerInner}>
  //       <div className={NavStyle.navbarContainer}>
  //         <button className={`${NavStyle.boxes}`}>
  //           <Link to="/">
  //             <div className={`${NavStyle.boxesInner}`}>
  //               <img src={home} alt=""></img>
  //               <div className={NavStyle.Textbox}>Αρχική</div>
  //             </div>
  //           </Link>
  //         </button>
  //         <button className={`${NavStyle.boxes}`}>
  //           <Link to="about">
  //             <div className={`${NavStyle.boxesInner}`}>
  //               <img src={news} alt=""></img>
  //               <div className={NavStyle.Textbox}>About</div>
  //             </div>
  //           </Link>
  //         </button>
  //         <button className={`${NavStyle.boxes}`}>
  //           <Link to="blog">
  //             <div className={`${NavStyle.boxesInner}`}>
  //               <img src={news} alt=""></img>
  //               <div className={NavStyle.Textbox}>Στήλες</div>
  //             </div>
  //           </Link>
  //         </button>
  //       </div>
  //     </div> */}
  //   </div>
  // );
};

export default TopBar;
