import styles from '../../styles/main.module.css'
import LineWithDot from './LineWithDot'

function BottomArticle(props: any) {
    const {article} = props;

    let imagePath = article.imagePath;

    if (article.author.id === 1) {
        imagePath = '/static/media/apeleutheros.png';
    }
    else if (article.author.id === 2) {
        imagePath = '/static/media/gamechanger.png';
    }
    else if (article.author.id === 3) {
        imagePath = '/static/media/sketch.png';
    }
    else if (article.author.id === 5) {
        imagePath = '/static/media/marketmaker.png';
    }


    return (
        <div className={styles.BottomArticlesDiv}>
            <div className={styles.homePageBottomArticleTitle}>
                {article.imagePath && <img src={imagePath} width={110} height={110}  style={{borderRadius: '100px', border: "2px solid #212529", padding: "1px", objectFit: "cover"}}/>}
            </div>
            
            <div className="d-flex flex-column">
                <div className={styles.homePageBottomArticleTitle}>
                    {article.author.description ? article.author.description : ''}
                </div>
                <LineWithDot
                    customstyle={{ width: "70px"}}
                />
                <div className={styles.homePageBottomArticleShortDescription}>
                    {article.preview ? article.preview : ''}
                </div>
                
                {/* <div className={styles.homePageBottomArticleDetails} dangerouslySetInnerHTML={{__html: article.preview ? article.preview + '...' : ''}}></div> */}
            </div>
        </div>
    )
}

export default BottomArticle