import React, {useEffect} from 'react';
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom'
import {useState} from 'react';
import './App.css';
import axios from 'axios';
import {BaseUrl} from './App';
import Container from 'react-bootstrap/esm/Container';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/esm/Row';
import { Col } from 'react-bootstrap';
import SideBar from './components/generalComponents/SideBar';

const GenericNotFound = () => {
    const navigate = useNavigate();
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        axios.post(BaseUrl + "/get-redirect-url", {
            url: window.location.href
        }).then((response) => {
            if (response.data.data.success) {
                navigate(response.data.data.path, { replace: true });
            } else {
                setNotFound(true);
            }
        }).catch((error)=> {
            setNotFound(true);
        })
    });

    if (!notFound) {
        return <div>Loading..</div>
    } else {
        return (
        <Container fluid className="h-100 px-lg-5 py-5 px-0">
            <Helmet>
                <title>{'The Morning View | Page not found'}</title>
            </Helmet>
            <div style={{display: 'flex', justifyContent: 'center', minHeight:'70vh'}}>
                <h3>404 - Η σελίδα δεν βρέθηκε</h3>
            </div>
        </Container>);
    }
}

export default GenericNotFound;
