import date from '../../images/datedark.png'
import styles from '../../styles/main.module.css'
import LineWithDot from './LineWithDot';
import { useNavigate } from 'react-router';

function AuthorsListedArticle(props: any) {
    const navigate = useNavigate();
    const goToAuthorsToArticle = (article: any) => {
        const state = {id: article.id};
        navigate("/authors-article/"+ article.url_key, {state: state});
    };
    const {article} = props;
    const backgroundImageLink = {backgroundImage: `url(${article.imagePath})`};
    return (
        <div className={styles.listedArticle}>
            <div className={styles.backgroundImgStyle} style={backgroundImageLink}>
            </div>
            <div className={styles.articleInfo}>
                <div className={styles.authorStyle}>
                    {article.author.description}
                </div>
                <div className="d-flex flex-column h-100 flex-fill pb-3">
                    <div className={styles.listedArticleDate}>
                        <img src={date} className={styles.dateLogo}></img>
                        {article.releaseDate}
                    </div>
                    <div className={styles.listedArticleTitle} style={{cursor: 'pointer'}} onClick={() => goToAuthorsToArticle(article)}>
                        {article.title.substring(0,50) + '...'}
                    </div>
                    <div className={styles.listedArticleDetails}>
                        <div dangerouslySetInnerHTML={{__html: article.preview ? article.preview.substring(0,120) + '...' : ''}}></div>
                    </div>
                </div>
                <div className={styles.underLine}>
                    <LineWithDot customstyle={{ width: "100px" }} />
                </div>
            </div>
        </div>
    )
}

export default AuthorsListedArticle