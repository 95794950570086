import classes from "../../../styles/Login.module.css"
import { Link } from "react-router-dom";
  
const EmailVerifiedContainer = () => {
    return (
        
        <div className={`${classes.Background}`}>
        <div className={`${classes.Container}`}>

            <div className={`${classes.insideContainerEmailVer}`}>
            <div className={`${classes.UpperText}`}>EMAIL VERIFIED!</div>
            <div className={`${classes.emailSentStyle} ${classes.information}`}>Το Email σας επιβεβαιώθηκε με επιτυχία!<br/>Ευχαριστουμε.</div>
            <Link to="/login"><div className={`${classes.loginForgotPassStyle}`}> Κάντε την σύνδεση</div></Link>

            </div>
        </div>
    </div>

)
}
export default EmailVerifiedContainer;