import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BaseUrl } from "../App";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HomePageContainer from "../components/pageComponents/HomePageComps/HomePageContainer";
import mainBanner from "../images/mobileBnr.png";
import android from "../images/androidImg.png";
import ios from "../images/iosImg.png";
import HomepageStyles from "../styles/Homepage.module.css";
import LoadingPage from "./LoadingPage";
import ReactDOM from "react-dom";
import BlogContainer from "../components/pageComponents/Blog/BlogContainer";
import { useLocation } from "react-router";
import Banner from "./Banner";
import { Helmet } from "react-helmet-async";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const HomePage = () => {
    const getTagId = () => {
        let tmpTagId = '';
        if (window.location.href.indexOf('/categories/apeleutheros') != -1) {
            return 1;
        } else if (window.location.href.indexOf('/categories/gamechanger') != -1) {
            return 2;
        } else if (window.location.href.indexOf('/categories/sketch') != -1) {
            return 3;
        } else if (window.location.href.indexOf('/categories/news-and-analysis') != -1) {
            return 4;
        } else if (window.location.href.indexOf('/categories/marketmaker') != -1) {
            return 5;
        }
        return tmpTagId;
    };
    const search = useLocation();
    const tag = search.state;
    var theTagId = getTagId();
    const [hasMoreArticles,setHasMoreArticles] = useState(true);
    const [theData,setData] = useState([] as any);
    const [page, setPage] = useState(1);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [Tags, setTags] = useState([]);
    const [newsAndAnalysisFrontPage, setNewsAndAnalysisFrontPage] = useState([]);
    const [bottomFeaturedArticles, setBottomFeaturedArticles] = useState([]);

    const loadNewsAndAnalysisFrontPage = () => {
        axios.get(BaseUrl + '/get-news-analysis-front-page').then((response)=> {
            setNewsAndAnalysisFrontPage(response.data.data);
        })
    };

    const loadBottomFeaturedArticles = () => {
        axios.get(BaseUrl + '/get-bottom-featured-articles').then((response) => {
            setBottomFeaturedArticles(response.data.data);
        })
    };

    const loadTags = () => {
        axios.get(BaseUrl+'/get-tags').then((response) => {
            setTags(response.data.data);
        });
    };

    const loadArticles = (pageNum = 1) => {
        axios.get(BaseUrl+'/get-articles', {
            params: {
                tagId: theTagId,
                page: pageNum
            }
        }).then((response) => {
            if (pageNum === 1) {
                setData(response.data.data.articles);
            } else {
                setData([...theData, ...response.data.data.articles]);
            }
            setHasMoreArticles(response.data.data.has_more_articles);
            loadTags();
        });
    };
    
    const loadMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        loadArticles(nextPage);
    };

    useEffect(() => {
        page !== 1 && setPage(1);
        loadArticles();
        loadNewsAndAnalysisFrontPage();
        loadBottomFeaturedArticles();
    }, [window.location.href]);

    return (
        <Container fluid className="h-100 px-lg-5 py-4 py-lg-5 px-0">
            <Helmet>
                <title>{'The Morning View'}</title>
            </Helmet>
            <BlogContainer blog={theData} tags={Tags} theTagId={theTagId} loadMore={loadMore} hasMoreArticles={hasMoreArticles} newsAndAnalysisFrontPage={newsAndAnalysisFrontPage} bottomFeaturedArticles={bottomFeaturedArticles} />
        </Container>
    )
    
}
export default HomePage;

