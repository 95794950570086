import axios from "axios";
import { ChangeEvent, useState } from "react";
import { BaseUrl } from "../../../App";
import styles from "../../../styles/ChangePass.module.css";

const ChangePassContainer = () => {
    const [oldpassword, setoldPassword] = useState("");
    const [newpassword, setnewPassword] = useState("");
    const [confirmnewpassword, setconfirmnewPassword] = useState("");
    const regexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)");
    const length = oldpassword.length;
    const [Required, setRequired] = useState(styles.Required);
    const [requiredCode, setrequiredCode] = useState(styles.requiredCode);
    const [validCode, setvalidCode] = useState(styles.validCode);
    const [validConfirmCode, setvalidConfirmCode] = useState(styles.validConfirmCode);
    const [errorText, setErrorText] = useState('');

    const changePassWithEnter = (event:any) => {
        if(event.key == "Enter"){
            handleDoneClick();
        }
    }

    const handleChangeOldPassword = (e: ChangeEvent<HTMLInputElement>) => {
        setoldPassword(e.target.value);
        setRequired(styles.Required);
    };
    const handleChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
        setnewPassword(e.target.value);
        setRequired(styles.Required);
        setrequiredCode(styles.requiredCode);
        setvalidConfirmCode(styles.validConfirmCode);
    };
    const handleChangeConfirmNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
        setconfirmnewPassword(e.target.value);
        setRequired(styles.Required);
        setvalidConfirmCode(styles.validConfirmCode);
    };

    const handleDoneClick = () => {
        if( newpassword === confirmnewpassword && newpassword.length>=8 && confirmnewpassword.length>=8 && regexp.test(newpassword)){
            axios.post(BaseUrl+'/change-password', {
                oldPass:oldpassword,
                newPass: newpassword,
            }, {
                headers: {
                    Authorization: sessionStorage.getItem('token')
                }
            }).then((response) => {
                window.location.replace('/login');
            }).catch((error)=>{
                setErrorText(error.response.data.message);
            })
        } else {
            // alert("Δεν τηρούνται οι κατάλληλες προϋποθέσεις");
            if(oldpassword === ""){
                setRequired(styles.RequiredTrue);
            } else if(oldpassword !== ""){
                setRequired(styles.Required);
            }
            if(newpassword===""){
                setrequiredCode(styles.requiredCodeTrue);
            }
            if(!regexp.test(newpassword) && newpassword!==""){
                setvalidCode(styles.validCodeTrue);
                setrequiredCode(styles.requiredCode);
            }
            else if(newpassword !== confirmnewpassword){
                setvalidCode(styles.validCode);
                setrequiredCode(styles.requiredCode);
                setvalidConfirmCode(styles.validConfirmCodeTrue);
            }
        }

    }

    return(
        <div className={styles.ChangePassContainer}>
            <div className={styles.FormText}>
                <p>Μετά την επιτυχημένη αλλαγή του κωδικού πρόσβασης,<br/> θα χρειαστεί να κάνετε σύνδεση, με τα νέα σας στοιχεία.</p>
            </div>
            <div className={styles.FormContainer}>
                <div className={styles.validcurrentpass}>{errorText}</div>
                <input type="password" value={oldpassword} placeholder="Τωρινός κωδικός..." onChange={handleChangeOldPassword} onKeyDown={changePassWithEnter}></input>
                    <div className={Required}>Το πεδίο είναι υποχρεωτικό. <span style={{color: 'var(--mainColor)'}}>*</span></div>

                <input type="password" value={newpassword} placeholder="Νέος κωδικός..." onChange={handleChangeNewPassword} onKeyDown={changePassWithEnter} />
                    <div className={requiredCode}>Το πεδίο είναι υποχρεωτικό. <span style={{color: 'var(--mainColor)'}}>*</span></div>
                        <div className={validCode} >
                            Απαιτήσεις κωδικού πρόσβασης:
                            Τουλάχιστον ένας ειδικός χαρακτήρας,
                            Τουλάχιστον ένα κεφαλαίο γράμμα,
                            Τουλάχιστον ένας αριθμός [0-9],
                            Τουλάχιστον 8 χαρακτήρες
                        </div>

                <input type="password" value={confirmnewpassword} placeholder="Επιβεβαίωση κωδικού..." onChange={handleChangeConfirmNewPassword} onKeyDown={changePassWithEnter} />
                    <div className={requiredCode}>Το πεδίο είναι υποχρεωτικό. <span style={{color: 'var(--mainColor)'}}>*</span></div>
                    <div className={validConfirmCode}>Οι κωδικοί δεν ταιριάζουν.</div>
                    
                <input className={styles.SubmitBtn} type="submit" value="Αποστολή" onClick={handleDoneClick}></input>
            </div>
        </div>
    )
}
export default ChangePassContainer;