import React, { Fragment } from 'react';
import Container from 'react-bootstrap/esm/Container';
import styles from '../../../styles/main.module.css';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import SideNews from '../../generalComponents/SideNews';
import BottomArticle from '../../generalComponents/BottomArticle';
import LineWithDot from '../../generalComponents/LineWithDot';
import Promotion from '../../generalComponents/Promotion';
import { useNavigate } from 'react-router';

function NewsAndAnalysis(props: any) {
  const { newsAndAnalysisFrontPage, bottomFeaturedArticles } = props;
  let firstArticle: any = null;
  let secondArticle: any = null;
  let thirdArticle: any = null;

  const bottomArticles = [null, null, null, null];
  
  const navigate = useNavigate();
  const goToArticle = (article: any) => {
    const state = { id: article.id };
    navigate("/article/" + article.url_key, { state: state });
  };
  
  if (newsAndAnalysisFrontPage && newsAndAnalysisFrontPage.length) {
    for (let i = 0; i < newsAndAnalysisFrontPage.length; i++) {
      if (newsAndAnalysisFrontPage[i].first_page_first_news) {
        firstArticle = newsAndAnalysisFrontPage[i];
      } else if (newsAndAnalysisFrontPage[i].first_page_first_news2) {
        secondArticle = newsAndAnalysisFrontPage[i];
      } else if (newsAndAnalysisFrontPage[i].first_page_first_news3) {
        thirdArticle = newsAndAnalysisFrontPage[i];
      }
    }
  }

  if (bottomFeaturedArticles && bottomFeaturedArticles.length) {
    for (let i = 0; i < bottomFeaturedArticles.length; i++) {
      if (bottomFeaturedArticles[i].bottom_article) {
        bottomArticles[0] = bottomFeaturedArticles[i];
      } else if (bottomFeaturedArticles[i].bottom_article2) {
        bottomArticles[1] = bottomFeaturedArticles[i];
      } else if (bottomFeaturedArticles[i].bottom_article3) {
        bottomArticles[2] = bottomFeaturedArticles[i];
      } else if (bottomFeaturedArticles[i].bottom_article4) {
        bottomArticles[3] = bottomFeaturedArticles[i];
      }
    }
  }

  let backgroundImageLink = { backgroundImage: `url(null)` };
  if (firstArticle) {
    backgroundImageLink = { backgroundImage: `url(${firstArticle.imagePath})` };
  }

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex align-items-center">
          <div className={styles.homePageBorders}></div>
          <div className={`${styles.homePageNewAnaly} fs-lg-2 fs-1`}>Columns</div>
          <div className={styles.homePageBorders}></div>
        </div>
      <Container className="shadow p-3">
        {(bottomArticles[0] || bottomArticles[1] || bottomArticles[2] || bottomArticles[3]) &&
          <Fragment>
            <Row>
              <Col className="col-12 col-md-6 mb-3">
                {
                  bottomArticles[0] && <div style={{ cursor: 'pointer' }} onClick={() => { goToArticle(bottomArticles[0]) }}><BottomArticle article={bottomArticles[0]} /></div>
                }
              </Col>
              <Col className="col-12 col-md-6 mb-3">
                {
                  bottomArticles[1] && <div style={{ cursor: 'pointer' }} onClick={() => { goToArticle(bottomArticles[1]) }}><BottomArticle article={bottomArticles[1]} /></div>
                }
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-md-6 mb-3">
                {
                  bottomArticles[2] && <div style={{ cursor: 'pointer' }} onClick={() => { goToArticle(bottomArticles[2]) }}><BottomArticle article={bottomArticles[2]} /></div>
                }
              </Col>
              <Col className="col-12 col-md-6 mb-3">
                {
                  bottomArticles[3] && <div style={{ cursor: 'pointer' }} onClick={() => { goToArticle(bottomArticles[3]) }}><BottomArticle article={bottomArticles[3]} /></div>
                }
              </Col>
            </Row>
          </Fragment>
        }
      </Container>
        <div className="d-flex align-items-center">
          <div className={styles.homePageBorders}></div>
          <div className={`${styles.homePageNewAnaly} fs-lg-2 fs-1`}>News&Analysis</div>
          <div className={styles.homePageBorders}></div>
        </div>
      <Container className='d-flex flex-column w-100 px-0 py-2'>
        <Row>
          <Col className='col-12 col-lg-6'>
            <div className={styles.homePageNewsBackground} style={backgroundImageLink}></div>
            <div>
              <div className={styles.homePageNewsTitle} onClick={() => { goToArticle(firstArticle) }} style={{ cursor: 'pointer' }}>
                {firstArticle?.title}
              </div>
              <div className={styles.homePageNewsDetails} dangerouslySetInnerHTML={{ __html: firstArticle?.preview ? firstArticle?.preview + '...' : '' }}></div>
            </div>
            <div className={styles.mainLine} style={{ transform: "translateY(-50%)" }}>
              <LineWithDot  customstyle={{ width: "100px" }} />
            </div>
          </Col>
          <Col className="d-flex flex-column justify-content-between pt-3">
            {
              secondArticle && <div style={{ cursor: 'pointer' }} onClick={() => { goToArticle(secondArticle) }}><SideNews article={secondArticle} /></div>
            }
            {
              thirdArticle && <div style={{ cursor: 'pointer' }} onClick={() => { goToArticle(thirdArticle) }}><SideNews article={thirdArticle} /></div>
            }
          </Col>
        </Row>
      </Container>
      <Promotion/>
    </div>
  );
}

export default NewsAndAnalysis;
