import React from 'react';
import classes from '../../../styles/ContactUs.module.css'
import location from '../../../images/location.png'
import phone from '../../../images/phone.png'
import email from '../../../images/email.png'
import site from '../../../images/site.png'
import twitter from '../../../images/twitter.png'
import facebook from '../../../images/facebook.png'
import twitterRed from '../../../images/twitter_red.png'
import facebookRed from '../../../images/facebook_red.png'
import redlines from '../../../images/redlines2.jpg'
import gmail from '../../../images/gmail.png'
import banner from '../../../images/banner.png'

export const NewTab = (url_var:string) => { 
    window.open( 
      url_var, "_blank");
}


const ContactUsContainer = () => {
    return (

        <div className={`${classes.mainContainer}`}>
            <div className={`${classes.mainBanner}`}>
                <div className={`${classes.bannerTextStyle}`}>CONTACT US</div>
            </div>
            <div className={`${classes.mainButtons}`}>
                <div className={`${classes.contentStyle}`} >
                    <img src={email} width="50px"/>
                    <div className={`${classes.textStyle}`}>EMAIL</div>
                    <a href="mailto:info@morningview.gr" className={`${classes.buttonStyle}`}>Find more</a>
                </div>
                <div className={`${classes.contentStyle}`} >
                    <img src={site} width="50px"/>
                    <div className={`${classes.textStyle}`}>WEBSITE</div>
                    <button className={`${classes.buttonStyle}`} onClick={()=>{NewTab('https://www.morningview.gr')}}>Find more</button>
                </div>
                <div className={`${classes.contentStyle}`}>
                    <img src={phone} width="50px"/>
                    <div className={`${classes.textStyle}`}>PHONE</div>
                    <a href='tel:+302109639049' className={`${classes.buttonStyle}`}>Find more</a>
                </div>
                <div className={`${classes.contentStyle}`}>
                    <img src={location} width="50px"/>
                    <div className={`${classes.textStyle}`}>LOCATION</div>
                    <button className={`${classes.buttonStyle}`} onClick={()=>{NewTab('https://www.google.com/maps/place/Leof.+Andrea+Siggrou+106,+Athina+117+41')}}>Find More</button>
                </div>
                <div className={`${classes.contentStyle}`}>
                    <img src={twitterRed} width="50px"/>
                    <div className={`${classes.textStyle}`}>TWITTER PAGE</div>
                    <button className={`${classes.buttonStyle}`} onClick={()=>{NewTab('https://www.google.com')}}>Find more</button>
                </div>
                <div className={`${classes.contentStyle}`}>
                    <img src={facebookRed} width="50px"/>
                    <div className={`${classes.textStyle}`}>FACEBOOK PAGE</div>
                    <button className={`${classes.buttonStyle}`} onClick={()=>{NewTab('https://www.google.com')}}>Find more</button>
                </div>
            </div>
        </div>



        // <div className={`${classes.articleContainer}`}>
        //     <div className={`${classes.articleContainerInner}`}>
        //         <div className={`${classes.headerStyleContact}`}>Επικοινωνία</div>
        //         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3145.6182173331904!2d23.723886!3d37.9626995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bd04e508d439%3A0xe07b231011c6d478!2sLeof.%20Andrea%20Siggrou%20106%2C%20Athina%20117%2041!5e0!3m2!1sen!2sgr!4v1671533050155!5m2!1sen!2sgr" ></iframe>
        //         <div className={`${classes.contentStyle}`} onClick={()=>{NewTab('mailto:themorningviewapp@gmail.com')}}>
        //             <img className={`${classes.padded}`} src={gmail} width="20px"/> themorningviewapp@gmail.com
        //         </div>
        //         <div className={`${classes.contentStyle}`} onClick={()=>{NewTab('https://www.morningview.gr')}}>
        //             <img className={`${classes.padded}`} src={site} width="20px"/> https://www.morningview.gr
        //         </div>
        //         <div className={`${classes.contentStyle}`} onClick={()=>{NewTab('tel:+302109639049')}}>
        //             <img className={`${classes.padded}`} src={phone}  width="20px"/> +302109639049
        //         </div>
        //         <div className={`${classes.contentStyle}`} onClick={()=>{NewTab('https://www.google.com/maps/place/Leof.+Andrea+Siggrou+106,+Athina+117+41')}}>
        //             <img className={`${classes.padded}`} src={location} width="20px"/> 106 Leoforos Syngroy, Athens, 11741
        //         </div>
        //         <div className={`${classes.headerStyleSocial}`}>Social Media</div>
        //         <div className={`${classes.contentStyleMedia}`} >
        //             <div onClick={()=>{NewTab('https://www.google.com')}}>
        //                 <img className={`${classes.paddedSocial}`} src={twitter}  width="30px"/>
        //             </div>
        //             <div onClick={()=>{NewTab('https://www.youtube.com')}}>
        //                 <img className={`${classes.paddedSocial}`} src={facebook}  width="30px"/>
        //             </div>
        //         </div>
        //     </div>
        // </div>

    
    )
}

export default ContactUsContainer;